import {useEffect, useMemo, useState} from "react";
import { WizardSelect } from "../components/WizardSelect/WizardSelect";
import WizardRelevance from "../components/WizardRelevance/WizardRelevance.tsx";
import { FormattedMessage } from "react-intl";
import { EIconType } from "../../../utils/helper";
import { Icon } from "../../../models/Icon/Icon";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { PropsStepI } from "../Wizard";
import {updatedTypologyOptions, typologyMap} from "../../../json/data.ts";
import {RelevanceModifierEnum} from "../../../enums/RelevanceModifierEnum.ts";

export const ProcedureSteps = ({ globalState, setGlobalState, groupConfiguration }: PropsStepI) => {

  /** Inizializza il globalState con i dati da groupConfiguration */
  useEffect(() => {
    if (groupConfiguration && !globalState.typologyRelevancesSelected) {
      console.log("🗄️ Group Configuration - Procedure:", groupConfiguration.typologyRelevances);
      setGlobalState(prev => ({
        ...prev,
        typologyRelevancesSelected: groupConfiguration.typologyRelevances || [],
      }));
    }
  }, [groupConfiguration, setGlobalState]);

  /** Selezione dell'opzione di procedura */
  const [selectedProcedure, setSelectedProcedure] = useState<{ value: string; label: string } | null>(null);
  const [selectedRelevance, setSelectedRelevance] = useState<RelevanceModifierEnum>(
      RelevanceModifierEnum.MEDIUM
  );

  /** Opzioni della select per le procedure */
  // const procedureOptions = useMemo(() => updatedTypologyOptions, []);
  const procedureOptions = useMemo(() =>
              updatedTypologyOptions.filter(option => option.value !== ""),
          []
      );

  /** Lista delle procedure selezionate (presa dal globalState) */
  const listAdded = useMemo(() => globalState.typologyRelevancesSelected || [], [globalState.typologyRelevancesSelected]);

  const [resetKey, setResetKey] = useState(0);

  /** Aggiunge o modifica una procedura selezionata */
  const handleAdd = () => {
    if (!selectedProcedure) return;

    const newProcedure = { code: selectedProcedure.value, value: selectedRelevance.toString() };

    setGlobalState(prev => {
      const currentProcedures = prev.typologyRelevancesSelected ?? [];

      const updatedProcedures = currentProcedures.some(item => item.code === newProcedure.code)
          ? currentProcedures.map(item => (item.code === newProcedure.code ? newProcedure : item))
          : [...currentProcedures, newProcedure];

      console.log("🔄 Updating procedures:", updatedProcedures);

      return { ...prev, typologyRelevancesSelected: updatedProcedures };
    });

    reset();
  };

  /** Rimuove una procedura dalla lista */
  const handleDelete = (codeToRemove: string) => {
    console.log("🗑️ Removing procedure:", codeToRemove);
    setGlobalState((prev) => ({
      ...prev,
      typologyRelevancesSelected: listAdded.filter((item) => item.code !== codeToRemove),
    }));
  };

  /** Reset della selezione */
  const reset = () => {
    setSelectedProcedure(null);
    setSelectedRelevance(RelevanceModifierEnum.MEDIUM);
    setResetKey(prevKey => prevKey + 1);
  };

  /** Disabilita il pulsante "Aggiungi" se non c'è una procedura selezionata */
  const isAddButtonDisabled = useMemo(() => !selectedProcedure, [selectedProcedure]);

  const disabled = useMemo(() => {
    return false;
  }, []);

  return (
    <>
      <div className="col-7 flex-fill d-flex flex-column gap-10 ">
        <WizardSelect
            key={resetKey}
            name="procedure"
            value={procedureOptions}
            // options={procedureOptions}
            label={{ title: "Wizard.choose-procedure-label" }}
            onChange={(selected) => {
              setSelectedProcedure(selected);
            }}
        />

        <WizardRelevance
            value={selectedRelevance ?? RelevanceModifierEnum.MEDIUM}
            onChange={setSelectedRelevance}
            label={{ title: "Wizard.choose-relevance-label" }}
        />
        <button
          className="btn btn-default d-flex align-items-center gap-2 text-white w-fit"
          style={{ background: "#82BA00" }}
          onClick={() => handleAdd()}
          disabled={isAddButtonDisabled}
        >
          <Icon
            nameIcon={"add"}
            typeIcon="material-icons"
            type={EIconType.MATERIAL}
          />
          <FormattedMessage id={"Wizard.add"} />
        </button>
      </div>
      <div className="col-5 flex-fill d-flex flex-column">
        <div className="d-flex align-items-center gap-4 py-2">
          <Icon
            nameIcon="bookmark_added"
            typeIcon="material-icons-outlined"
            type={EIconType.MATERIAL}
          />
          <p className="m-0 ">
            <FormattedMessage id={"Wizard.procedure-added"} />
          </p>
        </div>
        <div
          className="bg-white flex-fill  rounded-3 py-2 px-4 overflow-auto d-flex flex-column gap-2"
          style={{ minWidth: "40%" }}
        >
          {listAdded.length > 0 ? (
              listAdded.map((added, index) => (
                  <div key={index} className="d-flex rounded-2 align-items-center justify-content-between p-2"
                       style={{border: "1px solid #A2A5B5"}}>
                    {/*<p className="m-0">{added.label}</p>*/}
                    <p className="m-0 ps-2" style={{fontWeight: 700, color: "#8F8F8F"}}>{typologyMap[added.code] ?? added.code}</p>
                    <div className="d-flex align-items-center">
                      <p className="m-0 pe-1" style={{color: "#82BA00", fontWeight: 700}}>
                        <FormattedMessage id={`Wizard.relevance.${added.value}`}/>
                      </p>

                      <OverlayTrigger
                          key={"bottom"}
                          placement={"bottom"}
                          trigger={"click"}
                          overlay={
                            <Tooltip id={`tooltip-top`}>
                              <div className="cursor-pointer" onClick={() => handleDelete(added.code)}>
                                <FormattedMessage id="Wizard.delete"/>
                              </div>
                            </Tooltip>
                          }
                      >
                        <div>
                          <Icon className="cursor-pointer" type={EIconType.MATERIAL} nameIcon="more_vert"
                                typeIcon={"material-icons-outlined"}/>
                        </div>
                      </OverlayTrigger>
                    </div>
                  </div>
              ))
          ) : (
              <p className="text-muted">
                <FormattedMessage id="Wizard.no-selected" />
              </p>
          )}
        </div>
      </div>
    </>
  );
};
