export interface DefaultOption {
    readonly value: string;
    readonly label: string;
}

export const relevanceOptions: DefaultOption[] = [
    { value: '0..10', label: 'Tutte le rilevanze' },
    { value: '6.50..10', label: 'rilevanza alta (7-10)' },
    { value: '5.50..6.49', label: 'rilevanza (6)' },
    { value: '0..5.49', label: 'rilevanza bassa (0-5)' },
];

export const importsCompaniesProcessedOptions: DefaultOption[] = [
    { value: 'default', label: 'Tutte' },
    { value: 'false', label: 'Da processare' },
    { value: 'true', label: 'Processate' }
];

export const followOptions: DefaultOption[] = [
    { value: '', label: 'Tutte le procedure' },
    { value: 'false', label: 'procedure seguite in passato' },
    { value: 'true', label: 'che stai seguendo' },

];

export const companyStateOptions: DefaultOption[] = [
    { value: '', label: 'Tutti gli stati' },
    { value: 'new', label: 'nuova' },
    { value: 'acquired', label: 'acquisita' },
    { value: 'evaluation', label: 'inventario/perizia ricevuta' },
    { value: 'offerdo', label: 'fare offerta' },
    { value: 'offermade', label: 'offerta fatta' },
    { value: 'processing', label: 'in lavorazione' },
    { value: 'wait', label: 'aspettare' },
    { value: 'whattodo', label: 'cosa fare' },
    { value: 'todovisit', label: 'visita da fare' },
    { value: 'plannedvisit', label: 'visita pianificata' },
    { value: 'donevisit', label: 'visita effettuata' },
    { value: 'closed', label: 'chiusa' },

];

export const typologyOptions: DefaultOption[] =[
    { value: '', label: 'Tutte le tipologie' },
    { value: "AM", label: "ProcedureTypology.AM" },
    { value: "CN", label: "ProcedureTypology.CN" },
    { value: "LT", label: "ProcedureTypology.LT" },
    { value: "LZ", label: "ProcedureTypology.LZ" },
    { value: "PC", label: "ProcedureTypology.PC" },
    { value: "PR", label: "ProcedureTypology.PR" },
    { value: "AC", label: "ProcedureTypology.AC" },
    { value: "AG", label: "ProcedureTypology.AG" },
    { value: "IC", label: "ProcedureTypology.IC" },
    { value: "AP", label: "ProcedureTypology.AP" },
    { value: "AR", label: "ProcedureTypology.AR" },
    { value: "AS", label: "ProcedureTypology.AS" },
    { value: "BF", label: "ProcedureTypology.BF" },
    { value: "BS", label: "ProcedureTypology.BS" },
    { value: "CF", label: "ProcedureTypology.CF" },
    { value: "CP", label: "ProcedureTypology.CP" },
    { value: "CR", label: "ProcedureTypology.CR" },
    { value: "CS", label: "ProcedureTypology.CS" },
    { value: "FL", label: "ProcedureTypology.FL" },
    { value: "QL", label: "ProcedureTypology.QL" },
    { value: "LC", label: "ProcedureTypology.LC" },
    { value: "LG", label: "ProcedureTypology.LG" },
    { value: "LI", label: "ProcedureTypology.LI" },
    { value: "LV", label: "ProcedureTypology.LV" },
    { value: "SA", label: "ProcedureTypology.SA" },
    { value: "SB", label: "ProcedureTypology.SB" },
    { value: "SC", label: "ProcedureTypology.SC" },
    { value: "SG", label: "ProcedureTypology.SG" },
    { value: "SI", label: "ProcedureTypology.SI" },
    { value: "SL", label: "ProcedureTypology.SL" },
    { value: "SN", label: "ProcedureTypology.SN" },
    { value: "SQ", label: "ProcedureTypology.SQ" },
    { value: "SS", label: "ProcedureTypology.SS" },
    { value: "PU", label: "ProcedureTypology.PU" },
    { value: "EM", label: "ProcedureTypology.EM" },
    { value: "EI", label: "ProcedureTypology.EI" }
]

export const typologyMap = {
    AM: "amministrazione straordinaria grandi imprese",
    CN: "concordato minore",
    LT: "liquidazione controllata",
    LZ: "liquidazione giudiziale",
    PC: "concordato preventivo omologato",
    PR: "piano di ristrutturazione omologato (cci)",
    AC: "amministrazione controllata",
    AG: "amministrazione giudiziaria",
    IC: "impugnazioni concorsuali",
    AP: "apertura procedura di cancellazione",
    AR: "accordi di ristrutturazione dei debiti",
    AS: "amministrazione straordinaria",
    BF: "bancarotta fraudolenta",
    BS: "bancarotta semplice",
    CF: "concordato",
    CP: "concordato preventivo",
    CR: "concordato con riserva",
    CS: "concordato semplificato",
    FL: "fallimento",
    QL: "liquidazione coatta amministrativa (ccii)",
    LC: "liquidazione coatta amministrativa",
    LG: "liquidazione giudiziaria",
    LI: "liquidazione",
    LV: "liquidazione volontaria",
    SA: "scioglimento per atto dell’autorità",
    SB: "scioglimento senza liquidazione",
    SC: "scioglimento",
    SG: "sequestro giudiziario",
    SI: "stato di insolvenza",
    SL: "scioglimento e liquidazione",
    SN: "scioglimento senza messa in liquidazione",
    SQ: "sequestro conservativo di quote",
    SS: "scioglimento anticipato senza liquidazione",
    PU: "procedimento unitario",
    EM: "esecuzione mobiliare",
    EI: "esecuzione immobiliare",
};

export const updatedTypologyOptions = typologyOptions.map(option => {
    const key = option.value;
    return { value: key, label: key in typologyMap ? typologyMap[key] : option.label };
});

export const regionOptions: DefaultOption[] = [
    { value: '', label: 'Tutte le regioni' },
    { value: 'Abruzzo', label: 'Abruzzo' },
    { value: 'Basilicata', label: 'Basilicata' },
    { value: 'Calabria', label: 'Calabria' },
    { value: 'Campania', label: 'Campania' },
    { value: 'Emilia-Romagna', label: 'Emilia-Romagna' },
    { value: 'Friuli-Venezia Giulia', label: 'Friuli-Venezia Giulia' },
    { value: 'Lazio', label: 'Lazio' },
    { value: 'Liguria', label: 'Liguria' },
    { value: 'Lombardia', label: 'Lombardia' },
    { value: 'Marche', label: 'Marche' },
    { value: 'Molise', label: 'Molise' },
    { value: 'Piemonte', label: 'Piemonte' },
    { value: 'Puglia', label: 'Puglia' },
    { value: 'Sardegna', label: 'Sardegna' },
    { value: 'Sicilia', label: 'Sicilia' },
    { value: 'Toscana', label: 'Toscana' },
    { value: 'Trentino-Alto Adige', label: 'Trentino-Alto Adige' },
    { value: 'Umbria', label: 'Umbria' },
    { value: "Valle d'Aosta", label: "Valle d'Aosta" },
    { value: 'Veneto', label: 'Veneto' }
];

export const provinceOptions: DefaultOption[] = [
    { value: '', label: 'Tutte le province' },
    { value: 'Agrigento', label: 'Agrigento' },
    { value: 'Alessandria', label: 'Alessandria' },
    { value: 'Ancona', label: 'Ancona' },
    { value: 'Aosta', label: 'Aosta' },
    { value: 'Arezzo', label: 'Arezzo' },
    { value: 'Ascoli Piceno', label: 'Ascoli Piceno' },
    { value: 'Asti', label: 'Asti' },
    { value: 'Avellino', label: 'Avellino' },
    { value: 'Bari', label: 'Bari' },
    { value: 'Barletta-Andria-Trani', label: 'Barletta-Andria-Trani' },
    { value: 'Belluno', label: 'Belluno' },
    { value: 'Benevento', label: 'Benevento' },
    { value: 'Bergamo', label: 'Bergamo' },
    { value: 'Biella', label: 'Biella' },
    { value: 'Bologna', label: 'Bologna' },
    { value: 'Bolzano', label: 'Bolzano' },
    { value: 'Brescia', label: 'Brescia' },
    { value: 'Brindisi', label: 'Brindisi' },
    { value: 'Cagliari', label: 'Cagliari' },
    { value: 'Caltanissetta', label: 'Caltanissetta' },
    { value: 'Campobasso', label: 'Campobasso' },
    { value: 'Carbonia-Iglesias', label: 'Carbonia-Iglesias' },
    { value: 'Caserta', label: 'Caserta' },
    { value: 'Catania', label: 'Catania' },
    { value: 'Catanzaro', label: 'Catanzaro' },
    { value: 'Cosenza', label: 'Cosenza' },
    { value: 'Cremona', label: 'Cremona' },
    { value: 'Crotone', label: 'Crotone' },
    { value: 'Cuneo', label: 'Cuneo' },
    { value: 'Enna', label: 'Enna' },
    { value: 'Fermo', label: 'Fermo' },
    { value: 'Ferrara', label: 'Ferrara' },
    { value: 'Firenze', label: 'Firenze' },
    { value: 'Foggia', label: 'Foggia' },
    { value: 'Forlì-Cesena', label: 'Forlì-Cesena' },
    { value: 'Frosinone', label: 'Frosinone' },
    { value: 'Genova', label: 'Genova' },
    { value: 'Gorizia', label: 'Gorizia' },
    { value: 'Grosseto', label: 'Grosseto' },
    { value: 'Imperia', label: 'Imperia' },
    { value: 'Isernia', label: 'Isernia' },
    { value: 'La Spezia', label: 'La Spezia' },
    { value: "L\'Aquila", label: "L\'Aquila" },
    { value: 'Latina', label: 'Latina' },
    { value: 'Lecce', label: 'Lecce' },
    { value: 'Lecco', label: 'Lecco' },
    { value: 'Livorno', label: 'Livorno' },
    { value: 'Lodi', label: 'Lodi' },
    { value: 'Lucca', label: 'Lucca' },
    { value: 'Macerata', label: 'Macerata' },
    { value: 'Mantova', label: 'Mantova' },
    { value: 'Massa-Carrara', label: 'Massa-Carrara' },
    { value: 'Matera', label: 'Matera' },
    { value: 'Medio Campidano', label: 'Medio Campidano' },
    { value: 'Messina', label: 'Messina' },
    { value: 'Milano', label: 'Milano' },
    { value: 'Modena', label: 'Modena' },
    { value: 'Monza e Brianza', label: 'Monza e Brianza' },
    { value: 'Napoli', label: 'Napoli' },
    { value: 'Novara', label: 'Novara' },
    { value: 'Nuoro', label: 'Nuoro' },
    { value: 'Ogliastra', label: 'Ogliastra' },
    { value: 'Olbia-Tempio', label: 'Olbia-Tempio' },
    { value: 'Oristano', label: 'Oristano' },
    { value: 'Padova', label: 'Padova' },
    { value: 'Palermo', label: 'Palermo' },
    { value: 'Parma', label: 'Parma' },
    { value: 'Pavia', label: 'Pavia' },
    { value: 'Perugia', label: 'Perugia' },
    { value: 'Pesaro e Urbino', label: 'Pesaro e Urbino' },
    { value: 'Pescara', label: 'Pescara' },
    { value: 'Piacenza', label: 'Piacenza' },
    { value: 'Pisa', label: 'Pisa' },
    { value: 'Pistoia', label: 'Pistoia' },
    { value: 'Pordenone', label: 'Pordenone' },
    { value: 'Potenza', label: 'Potenza' },
    { value: 'Prato', label: 'Prato' },
    { value: 'Ragusa', label: 'Ragusa' },
    { value: 'Ravenna', label: 'Ravenna' },
    { value: 'Reggio di Calabria', label: 'Reggio di Calabria' },
    { value: 'Reggio Nell\'Emilia', label: 'Reggio Nell\'Emilia' },
    { value: 'Rieti', label: 'Rieti' },
    { value: 'Rimini', label: 'Rimini' },
    { value: 'Roma', label: 'Roma' },
    { value: 'Rovigo', label: 'Rovigo' },
    { value: 'Salerno', label: 'Salerno' },
    { value: 'Sassari', label: 'Sassari' },
    { value: 'Savona', label: 'Savona' },
    { value: 'Siena', label: 'Siena' },
    { value: 'Siracusa', label: 'Siracusa' },
    { value: 'Sondrio', label: 'Sondrio' },
    { value: 'Taranto', label: 'Taranto' },
    { value: 'Teramo', label: 'Teramo' },
    { value: 'Terni', label: 'Terni' },
    { value: 'Torino', label: 'Torino' },
    { value: 'Trapani', label: 'Trapani' },
    { value: 'Trento', label: 'Trento' },
    { value: 'Treviso', label: 'Treviso' },
    { value: 'Trieste', label: 'Trieste' },
    { value: 'Udine', label: 'Udine' },
    { value: 'Varese', label: 'Varese' },
    { value: 'Venezia', label: 'Venezia' },
    { value: 'Verbano-Cusio-Ossola', label: 'Verbano-Cusio-Ossola' },
    { value: 'Vercelli', label: 'Vercelli' },
    { value: 'Verona', label: 'Verona' },
    { value: 'Vibo Valentia', label: 'Vibo Valentia' },
    { value: 'Vicenza', label: 'Vicenza' },
    { value: 'Viterbo', label: 'Viterbo' },
];

export const atecoDataSample = [
    {
        code: "20",
        description: "Fabbricazione di sostanze chimiche",
        subcodes: [
            { code: "20.1", description: "Fabbricazione di prodotti chimici di base" },
            { code: "20.11", description: "Fabbricazione di gas industriali" },
            { code: "20.11.00", description: "Produzione di gas tecnici" },
            { code: "20.12", description: "Fabbricazione di coloranti e pigmenti" },
            { code: "20.2", description: "Fabbricazione di pesticidi e altri prodotti agrochimici" }
        ]
    },
    {
        code: "21",
        description: "Fabbricazione di prodotti farmaceutici",
        subcodes: [
            { code: "21.1", description: "Fabbricazione di sostanze farmaceutiche di base" },
            { code: "21.2", description: "Fabbricazione di medicinali e preparati farmaceutici" }
        ]
    }
];
