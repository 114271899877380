import clsx from "clsx";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Icon } from "../../../models/Icon/Icon";
import { EIconType } from "../../../utils/helper";
import { StepsName } from "../Wizard";
import atecoData from "../../../json/atecoData.json";
import {typologyMap} from "../../../json/data.ts";
import {locationsData} from "../../../json/locationsData.ts";
import {
  CardCodeAteco,
  CardProcedure,
  CardRegioni,
  CardAltriParametri,
  CardIntervals,
} from "../components/Cards";

export default function RiepilogoSteps({ globalState, setCurrentStep }) {

  const steps = [
    "intervals",
    "codeAteco",
    "procedures",
    "regions",
    "sales-employees-socialcapital",
  ];
  const handleChangeStep = (e: any, name: string) => {
    e.preventDefault();
    const step = {
      codeAteco: StepsName.CODICE_ATECO,
      procedures: StepsName.PROCEDURA,
      regions: StepsName.AREA_GEO,
      "sales-employees-socialcapital": StepsName.ALTRI_PAR,
    };
    setCurrentStep(step[name]);
  };
  const [openedAccordion, setOpenedAccordion] = useState<string>("intervals");
  const classNameContainer = "riepilogo-row";

  function calculateVoteRange(globalState) {
    const {
      typologyRelevancesSelected,
      locationRelevancesSelected,
      salesRangesRelevancesSelected,
      employeeRangesRelevancesSelected,
      capitalRangesRelevancesSelected,
    } = globalState;

    const voteRanges = (globalState.atecoCodeRelevancesSelected ?? []).map(({ code, score, fixed }) => {
      let min = parseFloat(score);
      let max = parseFloat(score);

      if (!fixed) {
        const arraysToCheck = [
          typologyRelevancesSelected ?? [],
          locationRelevancesSelected ?? [],
          salesRangesRelevancesSelected ?? [],
          employeeRangesRelevancesSelected ?? [],
          capitalRangesRelevancesSelected ?? [],
        ];

        arraysToCheck.forEach((arr) => {
          arr.forEach((item) => {
            if (item.value === "LOW") {
              min -= 0.5;
            } else if (item.value === "HIGH") {
              max += 0.5;
            }
          });
        });
      }

      return { code, min, max, score, fixed };
    });

    return voteRanges;
  }

  return (
      <div className="flex-fill d-flex gap-2 flex-column riepilogo">
        {steps.map((step, idx) => {
          return (
              <div
                  key={`accordion-${idx}`}
                  className="accordion accordion-icon-toggle"
                  id={`kt_accordion_${idx}_${step}`}
              >
                <div
                    className={clsx(
                        "accordion-header py-3 d-flex gap-5  border-bottom border-secondary justify-content-between",
                        openedAccordion === step ? "" : "collapsed",
                    )}
                    data-bs-toggle="collapse"
                    data-bs-target={`#kt_accordion_${idx}_${step}_item`}
                >
                  <p className="fw-bold m-0 d-flex align-items-center gap-2">
                    <FormattedMessage id={"Wizard.riepilogo." + step}/>
                    {step !== "intervals" && (
                        <div onClick={(e) => handleChangeStep(e, step)}>
                          <Icon
                              typeIcon="material-icons-outlined"
                              nameIcon="edit"
                              type={EIconType.MATERIAL}
                          ></Icon>
                        </div>
                    )}
                  </p>
                  <span className="accordion-icon">
                <i className="fa-solid fa-chevron-right"></i>
              </span>
                </div>
                <div
                    id={`kt_accordion_${idx}_${step}_item`}
                    className={clsx(
                        "fs-6 collapse",
                        openedAccordion === step ? "show" : "",
                    )}
                    data-bs-parent={`kt_accordion_${idx}_${step}`}
                >
                  <div className="px-4 py-3 ">
                    {step === "intervals" && (
                        <div className={classNameContainer}>
                          {calculateVoteRange(globalState).map(({ code, min, max, score, fixed }) => {
                            // Trova la descrizione del codice ATECO o del sottocodice
                            const selectedAteco = atecoData.find((ateco) => ateco.code === code) ||
                                atecoData.flatMap((ateco) => ateco.subcodes).find((sub) => sub.code === code);

                            return (
                                <CardIntervals
                                    key={code}
                                    voteMin={min}
                                    voteMax={max}
                                    score={score}
                                    fixed={fixed}
                                    description={selectedAteco?.description || "N/A"}
                                    code={code}
                                />
                            );
                          })}
                        </div>
                    )}
                    {step === "codeAteco" && (
                        <div className={classNameContainer}>
                          {(globalState.atecoCodeRelevancesSelected ?? []).map((codeAteco) => {

                            // Trova la descrizione del codice ATECO o del sottocodice
                            const selectedAteco = atecoData.find((ateco) => ateco.code === codeAteco.code) ||
                                atecoData.flatMap((ateco) => ateco.subcodes).find((sub) => sub.code === codeAteco.code);

                            return (
                                <CardCodeAteco
                                    key={codeAteco.code}
                                    value={codeAteco.score}
                                    description={selectedAteco?.description || "N/A"}
                                    code={codeAteco.code}
                                />
                            );
                          })}
                        </div>
                    )}
                    {step === "procedures" && (
                        <div className={classNameContainer}>
                          {(globalState.typologyRelevancesSelected ?? []).map((procedure) => {

                            // Trova la descrizione della procedura dal typologyMap
                            const description = typologyMap[procedure.code] || "N/A";

                            return (
                                <CardProcedure
                                    key={procedure.code}
                                    value={procedure.value}
                                    description={description}
                                />
                            );
                          })}
                        </div>
                    )}

                    {step === "regions" && (
                        <div className={classNameContainer}>
                          {(globalState.locationRelevancesSelected ?? []).map((location) => {

                            // Trova la regione associata alla provincia
                            const regione = locationsData.find((reg) =>
                                reg.provinces.includes(location.code)
                            );

                            return (
                                <CardRegioni
                                    key={location.code}
                                    regione={regione ? regione.label : location.code}
                                    province={regione ? [location.code] : []}
                                    relevance={location.value}
                                />
                            );
                          })}
                        </div>
                    )}

                    {step === "sales-employees-socialcapital" && (
                        <CardAltriParametri
                            data={{
                              sales: globalState.salesRangesRelevancesSelected ?? [],
                              employees: globalState.employeeRangesRelevancesSelected ?? [],
                              capital: globalState.capitalRangesRelevancesSelected ?? []
                            }}
                        />
                    )}
                  </div>
                </div>
              </div>
          );
        })}
      </div>
  );
}
