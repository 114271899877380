import { QueryState } from "../vendor/_metronic/helpers";
import * as yup from "yup";
import qs from "qs";
import { SettingDetail } from "../typings/dn";
import { store } from "../redux/store";
import { actionsTypeDetails } from "../redux/stores/details/detailsReducer";
import Layout from "../json/layout.json";
import { apiCall } from "./serviceCall";
import { NotificationReducerTypeActions } from "../redux/stores/notification/notificationReducer";
// enum
enum hydra {
  MEMBER = "hydra:member",
  MAPPING = "hydra:mapping",
  SEARCH = "hydra:search",
  VIEW = "hydra:view",
  TOTAL = "hydra:totalItems",
}
enum EIconType {
  FONTAWESOME = "fontawesome",
  MATERIAL = "material",
}
enum notificationTypes {
  NEWNOTE = "new note",
}
enum HTTPMethod {
  GET = "get",
  POST = "post",
  PUT = "put",
  PATCH = "patch",
  DELETE = "delete",
}
enum typeInput {
  "boolean" = "checkbox",
  "integer" = "number",
  "number" = "number",
  "string" = "text",
  "date-time" = "datetime-local",
}
enum ETypeNotifications {
  ATTACHMENTS = "attachments",
  INVENTORY = "inventory",
  APPRAISAL = "appraisal",
  NOTICE_OF_SALE = "notice_of_sale",
  MESSAGES = "messages",
  CLOSE_PROCEDURE = "close procedure",
  IMPORT = "import",
  IMPORT_PROCEDURE = "import procedure",
  OTHER = "other",
  INSERT_PROCEDURE = "insert procedure",
  CHANGE_RELEVANCE = "change relevance",
}

enum ECustomColor {
  GREY = "#617073",
  DARK = "#010001",
  RED = "#D33F49",
}

enum typeMethodModify {
  "ADD" = "add",
  "OVERRIDE" = "override",
}
enum SettingDetailParams {
  ROW = "Rows",
  COl = "Columns",
  KEY = "key",
  DYNAMIC = "DynamicComponent",
  DISPLAYNAME = "displayName",
  CONTENT = "content",
}
enum typeYup {
  "integer" = "number",
  "number" = "number",
  "string" = "string",
  "date-time" = "date",
  "array" = "array",
}
const initialOrderDnd: SettingDetail = {
  Rows: [],
};
function uniteValues(obj: any, keys: string[] | string) {
  if (Array.isArray(keys)) {
    const values = keys
      .filter((key) => obj.hasOwnProperty(key))
      .map((key) => obj[key]);
    return values.join(" ");
  } else {
    return obj[keys];
  }
}
function hexToRgb(hex) {
  hex = hex.replace(/^#/, "");
  let bigint = parseInt(hex, 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;
  return [r, g, b].join(",");
}
const getNotification = () => {
  const { userReducer } = store.getState();
  const { userData } = userReducer;

  const params = {
    params: {
      page: 1,
      itemsPerPage: 100,
      "notificationUser.user": userData!.id,
    },
  };
  apiCall(HTTPMethod.GET, `/notifications`, params).then((response) => {
    if (response) {
      const data = response.data["hydra:member"];
      store.dispatch({
        type: NotificationReducerTypeActions.SET_NOTIFICATION,
        payload: { notification: data },
      });
    }
  });
};
function generateUniqueId(): string {
  const timestamp = Date.now().toString(36); // Converti il timestamp in una stringa base-36
  const random = Math.random().toString(36).substring(2, 7); // Genera un numero casuale e ne prendi una porzione
  return timestamp + random;
}
function excludeFieldByName(fieldsToFilter: any) {
  const exclude = ["@context", "@id", "@type", "id"];
  return Object.keys(fieldsToFilter)
    .filter((key) => !exclude.includes(key))
    .reduce((obj, key) => {
      obj[key] = fieldsToFilter[key];
      return obj;
    }, {});
}
function parseDashboardDetailsPath(path: string, hash?: string) {
  const countPath = path.split("/").length;
  let pathName;
  let pathParent;
  let pathId;
  let hashId;
  let hashName;
  if (hash && hash !== "") {
    const formattedHash = hash.split("#").pop()?.split("/");
    if (formattedHash && formattedHash.length === 2) {
      hashId = formattedHash[1];
      hashName = formattedHash[0];
    }
  }
  if (countPath === 3) {
    pathName = path.split("/")[1];
    pathParent = pathName;
    pathId = path.split("/")[2];
  } else {
    pathName = path.split("/")[2];
    pathParent = path.split("/")[1];
    pathId = path.split("/")[3];
    path = "/" + pathName + "/" + pathId;
  }
  return { path, pathName, pathParent, pathId, hashId, hashName };
}
function checkIfValue<T>(value: T, findKey: string): T | null {
  if (value && value[findKey]) {
    return value[findKey];
  } else {
    return null;
  }
}
const takeNameFromIri = (value: string | string[]): string | null => {
  let valueToFormat;
  if (typeof value === "string") {
    if (value) {
      valueToFormat = value.split("/")[2];
    } else {
      return null;
    }
  } else {
    if (value && value.length > 0) {
      valueToFormat = value[0].split("/")[2];
    } else {
      return null;
    }
  }
  return valueToFormat;
};
function takeIdFromIri(iri: string | string[]): number | number[] | void {
  const extractId = (str: string) => parseInt(str.split("/").slice(-1)[0]);

  if (typeof iri === "string") {
    return extractId(iri);
  } else if (Array.isArray(iri)) {
    return iri.map(extractId);
  } else {
    // throw new Error('Input must be a string or an array of strings.');
  }
}

function printStringArray(arrayData) {
  return (
    <div>
      {arrayData.map((item, index) => (
        <span key={index}>
          {item}
          {index !== arrayData.length - 1 ? " ," : ""}
        </span>
      ))}
    </div>
  );
}
function getListOfIdFromIri(listOfIri) {
  const listIdMerged = [].concat.apply([], listOfIri);
  return eliminateDuplicates(listIdMerged);
}
export const getCustomInitilaFilter = (name: string) => {
  const globalState = store.getState();
  let customInitialFilter = {};
  if (name === "mail_models") {
    if (globalState?.userReducer?.userData?.groupUser?.[0]?.group)
      Object.assign(customInitialFilter, {
        "group.id": +globalState?.userReducer?.userData?.groupUser?.[0]?.group[
          "@id"
        ]
          .split("/")
          .pop(),
      });
  }
  return customInitialFilter;
};
export const getAutoFormValue = (entity: any) => {
  const globalState = store.getState();
  let formValue = {};
  if (entity?.list) {
    const autoForm = entity?.list?.autoForm;
    if (autoForm) {
      if (autoForm.includes("emailModelGroup")) {
        if (globalState?.userReducer?.userData?.groupUser?.[0]?.group)
          Object.assign(formValue, {
            group:
              globalState?.userReducer?.userData?.groupUser?.[0]?.group["@id"],
          });
      }
    }
  }
  return formValue;
};
function removeDuplicateObjects(objects) {
  const uniqueIDs = {};
  const uniqueObjects = objects.filter((obj) => {
    if (!uniqueIDs[obj["@id"]]) {
      uniqueIDs[obj["@id"]] = true;
      return true;
    }
    return false;
  });

  return uniqueObjects;
}
function convertToNumber(input) {
  if (typeof input === "string") {
    // Se l'input è una singola stringa, restituisci il numero corrispondente
    return parseInt(input);
  } else if (Array.isArray(input)) {
    // Se l'input è un array, mappa ogni elemento in un numero
    return input.map((item) => parseInt(item));
  }
}
// @todo tradurre senza hook
function createYupSchema(obj: any) {
  return Object.keys(obj).reduce((pre, key) => {
    let element = obj[key];
    let validator;
    if (element.type === "integer") {
      validator = yup["number"]();
    } else {
      if (yup[element.type]) {
        validator = yup[element.type]();
      }
    }

    if (!validator) {
      return pre;
    }
    element.attributes.forEach((attribute) => {
      if (!validator[attribute]) {
        return;
      }
      validator = validator.required("Campo obbligatorio");
    });
    pre[key] = validator;

    return pre;
  }, {});
}
// exclude obj element if is present into the string array
function excludeToObjectThisKeys(
  obj: { [key: string]: any },
  keysToExclude: string[]
): { [key: string]: any } {
  return Object.fromEntries(
    Object.entries(obj).filter(([key]) => !keysToExclude.includes(key))
  );
}
function filterObject(
  obj: { [key: string]: any },
  callback: (val, key) => void
) {
  return Object.fromEntries(
    Object.entries(obj).filter(([key, val]) => callback(val, key))
  );
}
function addRequiredProperty(obj, keysToCheck) {
  const updatedObject = {};

  for (const key of Object.keys(obj)) {
    updatedObject[key] = { ...obj[key], required: keysToCheck.includes(key) };
  }

  return updatedObject;
}
function saveNumberOfElementOnStore(pathStart, nameMain, value) {
  const { pathName, pathId } = parseDashboardDetailsPath(pathStart);
  const { detailsReducer } = store.getState();
  if (detailsReducer) {
    store.dispatch({
      type: actionsTypeDetails.numberElement,
      payload: {
        pathName: pathName,
        pathId: pathId,
        value: value,
        nameField: nameMain,
      },
    });
    // let stateToSave = Object.assign({}, prevStore);
    // store.dispatch({
    //     type: actionsTypeDetails.set,
    //     payload: stateToSave,
    //   });
    // let prevResult =
    //   prevStore?.[pathName]?.[pathId]?.["numberElement"]?.[nameMain];
    // if (prevStore?.[pathName]?.[pathId]?.["numberElement"]) {
    //   stateToSave[pathName][pathId]["numberElement"] = {
    //     ...prevStore?.[pathName]?.[pathId]?.["numberElement"],
    //     [nameMain]: value,
    //   };
    // } else {
    //   stateToSave[pathName][pathId]["numberElement"] = {
    //     [nameMain]: value,
    //   };
    // }
    // if (!prevResult && prevResult !== value) {
    //   store.dispatch({
    //     type: actionsTypeDetails.set,
    //     payload: stateToSave,
    //   });
    // }
  }
}
function excludeToObjIfIsReadOnly(obj: { [key: string]: any }): {
  [key: string]: any;
} {
  return Object.fromEntries(
    Object.entries(obj).filter(([key]) => !obj[key].readOnly)
  );
}
function getNumberPage(path: string | null): number | null {
  /* @ts-ignore */
  return path ? +path.split("page=").pop() : null;
}
function getDefaultFilterValue(currentEntity: any, dashboard?: boolean) {
  // Layout.entity['dashboard']?.filters
  const elementToUse = dashboard
    ? Layout.entity["dashboard"]?.filters
    : currentEntity?.filters;
  return elementToUse
    ? (elementToUse || [])
        .map(
          (filter) =>
            filter?.schema?.defaultValue && {
              [filter.name]: filter.schema.defaultValue,
            }
        )
        .filter(Boolean)
        .reduce((acc, currentObject) => Object.assign(acc, currentObject), {})
    : {};
}
function isIriReference(info: schemaTypeComponents): boolean {
  return (
    (info.format && info.format === "iri-reference") ||
    (info.type === "array" &&
      info.items &&
      info.items.format === "iri-reference") ||
    false
  );
}
function isMultIriReference(info: schemaTypeComponents): boolean {
  return (
    (info.type === "array" &&
      info.items &&
      info.items.format === "iri-reference") ||
    false
  );
}
function parseRequestQuery(query: string): QueryState {
  const cache: unknown = qs.parse(query, {
    ignoreQueryPrefix: true,
    depth: 0,
  });
  return cache as QueryState;
}
function deepFindFirstValueFromKey(obj, key = "main"): any | undefined {
  if (key in obj) {
    return obj[key];
  }
  for (const value of Object.values(obj)) {
    if (typeof value === "object" && value !== null) {
      const result = deepFindFirstValueFromKey(value, key);
      if (result !== undefined) {
        return result;
      }
    }
  }
  return undefined;
}
function deepExists(query: string | string[], obj: any): boolean {
  if (typeof obj === "string") {
    if (Array.isArray(query)) {
      return query.includes(obj);
    } else {
      return obj === query;
    }
  } else if (Array.isArray(obj)) {
    for (let i = 0; i < obj.length; i++) {
      if (deepExists(query, obj[i])) {
        return true;
      }
    }
  } else if (typeof obj === "object") {
    for (let key in obj) {
      if (deepExists(query, obj[key])) {
        return true;
      }
    }
  }
  return false;
}
export function returnValueFromCoordinates(
  obj: any,
  coordinates: (string | number)[]
): any {
  let result: any = obj;
  for (const coord of coordinates) {
    result = result[coord];
  }
  return result;
}

export function modifyObjectByCoordinates(
  obj: any,
  coordinates: (string | number)[],
  nameAttributes: string | (string | number)[],
  value: any,
  method: typeMethodModify = typeMethodModify.OVERRIDE
): any {
  let currentObj = obj;

  const targetObj = returnValueFromCoordinates(currentObj, coordinates);

  if (targetObj) {
    let attributeToUpdate;

    if (typeof nameAttributes === "string") {
      attributeToUpdate = nameAttributes;
    } else if (Array.isArray(nameAttributes)) {
      attributeToUpdate = returnValueFromCoordinates(targetObj, nameAttributes);
    }

    if (attributeToUpdate !== undefined) {
      switch (method) {
        case typeMethodModify.ADD:
          if (!Array.isArray(attributeToUpdate)) {
            attributeToUpdate = [];
          }
          attributeToUpdate.push(value);
          break;
        case typeMethodModify.OVERRIDE:
          attributeToUpdate = value;
          break;
      }
    }
  }

  return obj;
}
export function findCoordinatesByKey(
  obj: any,
  targetKey: string
): (string | number)[] {
  let coordinates: (string | number)[] = [];
  function search(obj: any, path: (string | number)[] = []): void {
    if (obj && typeof obj === "object") {
      if (obj.key === targetKey) {
        coordinates = path;
        return;
      }

      if (Array.isArray(obj)) {
        for (let i = 0; i < obj.length; i++) {
          search(obj[i], path.concat(i));
        }
      } else {
        for (const key in obj) {
          if (key !== "key") {
            search(obj[key], path.concat(key));
          }
        }
      }
    }
  }

  search(obj);
  return coordinates;
}
function formatUppercase(value: string) {
  return (value ? value.toUpperCase() : '');
}
function isObjectEmpty(obj) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false; // Se trovi almeno una chiave, l'oggetto non è vuoto
    }
  }
  return true; // Se non trovi nessuna chiave, l'oggetto è vuoto
}
function formatFirstUppercase(value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}
const getOptionsAsyncSelect = (inputValue: string = "", timerRef, callback) => {
  return new Promise<any>(async (resolve, reject) => {
    clearTimeout(timerRef.current);

    timerRef.current = setTimeout(async () => {
      try {
        const result = await callback();
        resolve(result);
      } catch (error) {
        reject(error);
      }
    }, 500);
  });
};
function areDeeplyEqual(obj1, obj2) {
  if (obj1 === obj2) return true;

  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) return false;

    return obj1.every((elem, index) => {
      return areDeeplyEqual(elem, obj2[index]);
    });
  }

  if (
    typeof obj1 === "object" &&
    typeof obj2 === "object" &&
    obj1 !== null &&
    obj2 !== null
  ) {
    if (Array.isArray(obj1) || Array.isArray(obj2)) return false;

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (
      keys1.length !== keys2.length ||
      !keys1.every((key) => keys2.includes(key))
    )
      return false;

    for (let key in obj1) {
      let isEqual = areDeeplyEqual(obj1[key], obj2[key]);
      if (!isEqual) {
        return false;
      }
    }

    return true;
  }

  return false;
}
function isObjectEquals(objA, objB) {
  // Ottieni le chiavi delle proprietà degli oggetti
  const keyA = Object.keys(objA);
  const keyB = Object.keys(objB);

  // Verifica se il numero di chiavi è lo stesso
  if (keyA.length !== keyB.length) {
    return false;
  }

  // Verifica se le chiavi corrispondono
  for (const key of keyA) {
    if (!keyB.includes(key)) {
      return false;
    }

    // Verifica se i valori corrispondono
    if (objA[key] !== objB[key]) {
      return false;
    }
  }

  // Se tutte le verifiche passano, gli oggetti sono uguali
  return true;
}
function isoToFormattedDate(inputDate: string) {
  const dateObject = new Date(inputDate);
  const month = dateObject.getMonth() + 1;
  return `${dateObject.getDate().toString().padStart(2, "0")}/${month
    .toString()
    .padStart(2, "0")}/${dateObject.getFullYear()}`;
}

function isoToFormattedTime(inputDate: string) {
  const dateObject = new Date(inputDate);
  return `${dateObject.getHours().toString().padStart(2, "0")}:${dateObject
    .getMinutes()
    .toString()
    .padStart(2, "0")}`;
}

function eliminateDuplicates(arr: string[]): string[] {
  return arr.filter((value, index, self) => self.indexOf(value) === index);
}

// interface
interface schemaTypeComponents {
  type: "string" | "interger" | "number" | "iri-reference" | "array";
  format?: "date-time" | "iri-reference";
  nullable?: boolean;
  items?: {
    type: string;
    format: "iri-reference";
  };
  editor?: any;
}

interface SchemasDocsJsonProperties {
  type: string;
  format?: string;
  nullable?: boolean;
  description?: string;
  editor?: any;
}
function dateIsValid(date) {
  return date instanceof Date && !isNaN(date as any);
}
interface typeOfObject {
  [key: string]: any | undefined;
}
const handleStore = (
  value: any,
  options: {
    pathName: string;
    pathId: string;
    formField: string;
  }
): void => {
  const { detailsReducer } = store.getState();
  const { pathName, pathId, formField } = options;
  const prevStore = Object.assign({}, detailsReducer);
  const prevValue = prevStore?.[pathName]?.[pathId]?.form?.[formField];
  if (areDeeplyEqual(prevValue, value)) {
    return;
  }
  store.dispatch({
    type: actionsTypeDetails.newSet,
    payload: {
      pathName: pathName,
      pathId: pathId,
      value: value,
      nameField: formField,
    },
  });
};

const sleep = (ms: number) =>  new Promise(resolve => setTimeout(resolve, ms));

export {
  initialOrderDnd,
  handleStore,
  hydra,
  typeInput,
  typeYup,
  EIconType,
  notificationTypes,
  HTTPMethod,
  typeOfObject,
  SchemasDocsJsonProperties,
  typeMethodModify,
  schemaTypeComponents,
  SettingDetailParams,
  ETypeNotifications,
  filterObject,
  deepFindFirstValueFromKey,
  saveNumberOfElementOnStore,
  getOptionsAsyncSelect,
  excludeFieldByName,
  getDefaultFilterValue,
  isObjectEquals,
  printStringArray,
  parseDashboardDetailsPath,
  getNotification,
  isObjectEmpty,
  formatUppercase,
  areDeeplyEqual,
  formatFirstUppercase,
  isoToFormattedDate,
  isoToFormattedTime,
  takeNameFromIri,
  uniteValues,
  createYupSchema,
  isMultIriReference,
  addRequiredProperty,
  dateIsValid,
  generateUniqueId,
  excludeToObjectThisKeys,
  excludeToObjIfIsReadOnly,
  isIriReference,
  convertToNumber,
  getListOfIdFromIri,
  removeDuplicateObjects,
  takeIdFromIri,
  eliminateDuplicates,
  checkIfValue,
  hexToRgb,
  deepExists,
  parseRequestQuery,
  getNumberPage,
  ECustomColor,
  sleep
};
