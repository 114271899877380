import {FormattedMessage} from "react-intl";

export function CardProcedure({ description, value }) {
    return (
        <div className="border border-secondary border-radius-1 fw-normal bg-white p-2 d-flex justify-content-between riepilogo-col">
            <p className="m-0">
                {/*<span className="text-primary fw-bold">{description}</span>*/}
                {description}
            </p>
            <p className="m-0 fw-bold text-success">
                <FormattedMessage id={`Wizard.relevance.${value}`} />
            </p>
        </div>
    );
}
