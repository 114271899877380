import {RelevanceKeysEnum} from "../enums/RelevanceKeysEnum.ts";

export const parametersMapping = [
    { key: RelevanceKeysEnum.SALES_LESS_MILLION, icon: "savings", category: "sales" },
    { key: RelevanceKeysEnum.SALES_BETWEEN_1_AND_5_MILLION, icon: "savings", category: "sales" },
    { key: RelevanceKeysEnum.SALES_BETWEEN_5_AND_10_MILLION, icon: "savings", category: "sales" },
    { key: RelevanceKeysEnum.SALES_OVER_10_MILLION, icon: "savings", category: "sales" },

    { key: RelevanceKeysEnum.EMPLOYEES_LESS_25, icon: "groups", category: "employee" },
    { key: RelevanceKeysEnum.EMPLOYEES_BETWEEN_26_AND_100, icon: "groups", category: "employee" },
    { key: RelevanceKeysEnum.EMPLOYEES_BETWEEN_101_AND_500, icon: "groups", category: "employee" },
    { key: RelevanceKeysEnum.EMPLOYEES_OVER_500, icon: "groups", category: "employee" },

    { key: RelevanceKeysEnum.CAPITAL_LESS_MILLION, icon: "trending_up", category: "capital" },
    { key: RelevanceKeysEnum.CAPITAL_BETWEEN_1_AND_5_MILLION, icon: "trending_up", category: "capital" },
    { key: RelevanceKeysEnum.CAPITAL_BETWEEN_5_AND_10_MILLION, icon: "trending_up", category: "capital" },
    { key: RelevanceKeysEnum.CAPITAL_OVER_10_MILLION, icon: "trending_up", category: "capital" },
];
