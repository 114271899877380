export const Entities = {
  COMPANIES: "companies",
};

export interface ApiPlatformWrapper<T> {
  data: T;
}

export interface User {
  id: number;
}

export interface Group {
  id?: number;
  name?: string;
  shortName?: string;
  active?: boolean;
  importConfiguration?: GroupImportConfigurationDto;
}

export interface GroupImportConfigurationDto {
  strategy?: string;
  atecoCodeRelevances?: AtecoScoreDto[];
  typologyRelevances?: RelevanceDto[];
  locationRelevances?: RelevanceDto[];
  salesRangesRelevances?: RelevanceDto[];
  employeeRangesRelevances?: RelevanceDto[];
  capitalRangesRelevances?: RelevanceDto[];

  // Methods for setting properties
  setAtecoCodeRelevances?(atecoCodeRelevances: AtecoScoreDto[]): void;
  setTypologyRelevances?(typologyRelevances: RelevanceDto[]): void;
  setLocationRelevances?(locationRelevances: RelevanceDto[]): void;
  setSalesRangesRelevances?(salesRangesRelevances: RelevanceDto[]): void;
  setEmployeeRangesRelevances?(employeeRangesRelevances: RelevanceDto[]): void;
  setCapitalRangesRelevances?(capitalRangesRelevances: RelevanceDto[]): void;
}

export interface RelevanceDto {
  code: string;
  value: string;
}
export interface AtecoScoreDto {
  code: string;
  score: number;
  fixed: boolean;
}
