export function CardCodeAteco({ description, code, value }) {
    return (
        <div className="border border-secondary border-radius-1 fw-normal bg-white p-2 d-flex justify-content-between riepilogo-col ">
            <p className="m-0">
                <span className="fw-bold">{code}</span> - {description}
            </p>
            <p className="m-0 fw-bold text-success">{value}</p>
        </div>
    );
}
