export const locationsData = [
    {
        label: "Abruzzo",
        provinces: ["L'Aquila", "Teramo", "Pescara", "Chieti"]
    },
    {
        label: "Basilicata",
        provinces: ["Potenza", "Matera"]
    },
    {
        label: "Calabria",
        provinces: ["Catanzaro", "Cosenza", "Reggio di Calabria", "Crotone", "Vibo Valentia"]
    },
    {
        label: "Campania",
        provinces: ["Napoli", "Salerno", "Caserta", "Avellino", "Benevento"]
    },
    {
        label: "Emilia-Romagna",
        provinces: ["Bologna", "Parma", "Modena", "Reggio Nell'Emilia", "Ferrara", "Ravenna", "Rimini", "Forlì-Cesena", "Piacenza"]
    },
    {
        label: "Friuli-Venezia Giulia",
        provinces: ["Udine", "Pordenone", "Gorizia", "Trieste"]
    },
    {
        label: "Lazio",
        provinces: ["Roma", "Latina", "Frosinone", "Viterbo", "Rieti"]
    },
    {
        label: "Liguria",
        provinces: ["Genova", "Imperia", "La Spezia", "Savona"]
    },
    {
        label: "Lombardia",
        provinces: ["Milano", "Bergamo", "Brescia", "Como", "Cremona", "Lecco", "Lodi", "Mantova", "Monza e Brianza", "Pavia", "Sondrio", "Varese"]
    },
    {
        label: "Marche",
        provinces: ["Ancona", "Ascoli Piceno", "Macerata", "Pesaro e Urbino", "Fermo"]
    },
    {
        label: "Molise",
        provinces: ["Campobasso", "Isernia"]
    },
    {
        label: "Piemonte",
        provinces: ["Torino", "Alessandria", "Asti", "Biella", "Cuneo", "Novara", "Verbano-Cusio-Ossola", "Vercelli"]
    },
    {
        label: "Puglia",
        provinces: ["Bari", "Brindisi", "Lecce", "Foggia", "Taranto", "Barletta-Andria-Trani"]
    },
    {
        label: "Sicilia",
        provinces: ["Palermo", "Catania", "Messina", "Agrigento", "Caltanissetta", "Enna", "Ragusa", "Siracusa", "Trapani"]
    },
    {
        label: "Sardegna",
        provinces: ["Cagliari", "Carbonia-Iglesias", "Medio Campidano", "Nuoro", "Ogliastra", "Olbia-Tempio", "Oristano", "Sassari"]
    },
    {
        label: "Toscana",
        provinces: ["Firenze", "Arezzo", "Grosseto", "Livorno", "Lucca", "Massa-Carrara", "Pisa", "Pistoia", "Prato", "Siena"]
    },
    {
        label: "Trentino-Alto Adige",
        provinces: ["Trento", "Bolzano"]
    },
    {
        label: "Umbria",
        provinces: ["Perugia", "Terni"]
    },
    {
        label: "Valle d'Aosta",
        provinces: []
    },
    {
        label: "Veneto",
        provinces: ["Venezia", "Verona", "Vicenza", "Padova", "Treviso", "Rovigo", "Belluno"]
    }
];
