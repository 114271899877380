import {useEffect, useMemo, useState} from "react";
import { WizardSelect } from "../components/WizardSelect/WizardSelect";
import { FormattedMessage } from "react-intl";
import { Icon } from "../../../models/Icon/Icon";
import {EIconType} from "../../../utils/helper";
import clsx from "clsx";
import { OverlayTrigger, Tooltip, Popover } from "react-bootstrap";
import { PropsStepI } from "../Wizard";
import WizardList from "../components/WizardList/WizardList.tsx";
import atecoData from "../../../json/atecoData.json";

export default function CodeAtecoSteps({globalState, setGlobalState, groupConfiguration,}: PropsStepI) {

  /** Sincronizzazione tra `groupConfiguration` e `globalState` **/
  useEffect(() => {
    if (groupConfiguration && !globalState.atecoCodeRelevancesSelected) {
      console.log("🗄️ Group Configuration ATECO Codes:", groupConfiguration.atecoCodeRelevances);
      setGlobalState((prev) => ({
        ...prev,
        atecoCodeRelevancesSelected: groupConfiguration.atecoCodeRelevances || [],
      }));
    }
  }, [groupConfiguration, setGlobalState]);

  /** Opzioni per la select dei Codici ATECO **/
  const codeAtecoOptions = useMemo(() => {
        return atecoData.map((ateco) => ({
          value: ateco.code,
          label: `${ateco.code} - ${ateco.description}`,
          subcodes: ateco.subcodes || []
        }));
      }, []);

  const selectedAtecoCodes = globalState.atecoCodeRelevancesSelected || [];
  const [codiceAtecoSelected, setCodiceAtecoSelected] = useState<string | null>(null);
  const [subCodiceAtecoSelected, setSubCodiceAtecoSelected] = useState<string | null>(null);
  const [subCodiceAtecoOptions, setSubCodiceAtecoOptions] = useState<{ code: string; description: string }[]>([]);
  const [votaValue, setVotaValue] = useState<number>(0);
  const [isFixed, setIsFixed] = useState<boolean>(false);
  const [resetKey, setResetKey] = useState(0);

  /** Gestione selezione primo livello */
  const handleCodiceAtecoChange = (selected) => {
    const selectedCode = selected.value;
    setCodiceAtecoSelected(selectedCode);

    // Trova i sottocodici (secondo livello) relativi al codice selezionato
    const selectedAteco = codeAtecoOptions.find((ateco) => ateco.value === selectedCode);
    setSubCodiceAtecoOptions(selectedAteco?.subcodes || []);
  };

  /** Gestione selezione secondo livello */
  const handleSubCodiceAtecoChange = (selected) => {
    setSubCodiceAtecoSelected(selected.code);
  };

  const handleAdd = () => {
    if (!codiceAtecoSelected) return;

    const newSelection = {
      code: subCodiceAtecoSelected || codiceAtecoSelected,
      score: votaValue,
      fixed: isFixed,
    };

    setGlobalState((prev) => {
      const currentSelections = prev.atecoCodeRelevancesSelected ?? [];

      const updatedSelections = currentSelections.map((item) =>
          item.code === newSelection.code ? newSelection : item
      );

      if (!currentSelections.some((item) => item.code === newSelection.code)) {
        updatedSelections.push(newSelection);
      }

      console.log("🔄 Updating ATECO Codes:", updatedSelections);

      return { ...prev, atecoCodeRelevancesSelected: updatedSelections };
    });

    reset();
  };

  const handleDelete = (codeToRemove: string) => {
    console.log("🗑️ Removing Ateco Code:", codeToRemove);

    setGlobalState((prev) => ({
      ...prev,
      atecoCodeRelevancesSelected: prev.atecoCodeRelevancesSelected
          ? prev.atecoCodeRelevancesSelected.filter((item) => item.code !== codeToRemove)
          : [],
    }));
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (/^(10|[0-9]?)$/.test(inputValue)) {
      setVotaValue(+inputValue);
    }
  };

  const reset = () => {
    setIsFixed(false);
    setCodiceAtecoSelected(null);
    setSubCodiceAtecoSelected(null);
    setVotaValue(0);
    setResetKey(prevKey => prevKey + 1);
  };

  const isAddButtonDisabled = useMemo(() => !codiceAtecoSelected && !votaValue, [codiceAtecoSelected, votaValue]);

  return (
      <>
        <div className="col-7 flex-fill d-flex flex-column gap-10">
          <WizardSelect
              key={resetKey}
              name="codice-ateco"
              value={codeAtecoOptions}
              label={{ title: "Wizard.choose-ateco-code-label", note: "Wizard.choose-ateco-code-note" }}
              onChange={handleCodiceAtecoChange}
          />

          {codiceAtecoSelected && subCodiceAtecoOptions.length > 0 && (
              <WizardList
                  value={subCodiceAtecoOptions}
                  label={{ title: "Wizard.choose-ateco-subcode-label", note: "Wizard.choose-ateco-subcode-note" }}
                  onChange={handleSubCodiceAtecoChange}
              />
          )}

          <div className="d-flex align-items-center gap-4">
            <div>
              <div className="d-flex align-items-center gap-4 py-2">
                <Icon nameIcon={"sentiment_very_satisfied"} typeIcon="material-icons-outlined"
                      type={EIconType.MATERIAL}/>
                <p className="m-0"><FormattedMessage id={"Wizard.vote"}/></p>
              </div>
              <div className="d-flex align-items-center bg-white rounded-4 p-2">
                <Icon className={clsx(isFixed ? "visible" : "invisible")} typeIcon="material-icons"
                      nameIcon="lock_outline" type={EIconType.MATERIAL}/>
                <input className="form-control form-control-solid bg-white outline-none border-0" type="number"
                       disabled={isFixed} value={votaValue} onChange={handleChange}/>
              </div>
            </div>
            <div>
              {/* checkbox  */}
              <label className="form-check form-check-custom gap-2  align-items-start">
                <input
                    className="form-check-input p-1"
                    type="checkbox"
                    checked={isFixed}
                    onChange={(e) => setIsFixed(e.target.checked)}
                />

                <span className=" d-flex flex-column align-items-start">
                <span className="">
                  <FormattedMessage id={"Wizard.choose-ateco-block-vote"}/>
                </span>
              </span>
              </label>
            </div>
          </div>

          <button className="btn btn-default d-flex align-items-center gap-2 text-white w-fit"
                  style={{background: "#82BA00"}} onClick={handleAdd} disabled={isAddButtonDisabled}>
            <Icon nameIcon={"add"} typeIcon="material-icons" type={EIconType.MATERIAL}/>
            <FormattedMessage id={"Wizard.add"}/>
          </button>
        </div>

        <div className="col-5 flex-fill d-flex flex-column">
          <div className="d-flex align-items-center gap-4 py-2">
            <Icon nameIcon="bookmark_added" typeIcon="material-icons-outlined" type={EIconType.MATERIAL}/>
            <p className="m-0"><FormattedMessage id={"Wizard.ateco-added"}/></p>
          </div>
          <div className="bg-white rounded-3 py-2 px-4 overflow-scroll d-flex flex-column gap-2"
               style={{height: "100%"}}>
            {selectedAtecoCodes.length > 0 ? selectedAtecoCodes.map((selected, index) => {
              // Trova la descrizione del codice ATECO o del sottocodice
              const selectedAteco = atecoData.find((ateco) => ateco.code === selected.code) ||
                  atecoData.flatMap((ateco) => ateco.subcodes).find((sub) => sub.code === selected.code);

              return (
                  <div key={index} className="d-flex rounded-2 align-items-center justify-content-between p-2"
                       style={{border: "1px solid #A2A5B5"}}>
                    <div className="d-flex align-items-center gap-2">
                      <p className="m-0 ps-2" style={{fontWeight: 700, color: "#8F8F8F"}}>
                        {selected.code}
                      </p>
                      <p className="m-0" style={{fontWeight: 500, color: "#8F8F8F"}}>
                        - {selectedAteco?.description || "N/A"}
                      </p>
                    </div>
                    <div className="d-flex align-items-center">
                      <p className="m-0 pe-2" style={{color: "#82BA00", fontWeight: 700}}>{selected.score}</p>
                      <OverlayTrigger
                          key={"bottom"}
                          placement={"bottom"}
                          trigger={"click"}
                          overlay={
                            <Tooltip id={`tooltip-top`}>
                              <div className="cursor-pointer" onClick={() => handleDelete(selected.code)}>
                                <FormattedMessage id="Wizard.delete"/>
                              </div>
                            </Tooltip>
                          }
                      >
                        <div>
                          <Icon className="cursor-pointer" type={EIconType.MATERIAL} nameIcon="more_vert"
                                typeIcon={"material-icons-outlined"}/>
                        </div>
                      </OverlayTrigger>
                    </div>
                  </div>
              );
            }) : <p className="text-muted"><FormattedMessage id="Wizard.no-selected"/></p>}
          </div>
        </div>
      </>
  );
}
