import React, { FC, useEffect, useState } from "react";

import Skeleton from "react-loading-skeleton";
import Modal from "react-bootstrap/Modal";
import {
  takeIdFromIri,
  hydra,
  HTTPMethod,
  eliminateDuplicates,
  generateUniqueId,
  formatFirstUppercase,
  formatUppercase,
  saveNumberOfElementOnStore,
  parseDashboardDetailsPath,
  handleStore,
} from "../../../../utils/helper";
import { apiCall } from "../../../../utils/serviceCall";
import { parseDataFromGlobalContext } from "../../../../redux/stores/rootContext";
import { store } from "../../../../redux/store";
import FormModal from "../../../FormModal/FormModal";
import moment from "moment";
import { useIntl } from "react-intl";
import BasicInput from "../BasicInput";
import {
  MessageTypeEnum,
  MessagesReducerTypeActions,
} from "../../../../redux/stores/message/messageReducer";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButton from "../../../buttons/CustomButton";
import Form from "../../../Form/Form";
import { DynamicComponentNameFields } from "../..";
import { CuratorCompanyControllerApi } from "../../../../apis/curatorCompanyController";
import { actionsTypeDetails } from "../../../../redux/stores/details/detailsReducer";

interface typeField {
  type: string;
  field?: string;
  reference?: string;
  topFields?: string[];
  bottomFields?: string[];
  icon?: string;
}
export default function ManyToManyComplex({ props }) {
  const path: string = props.type.editor.path;
  const intl = useIntl();
  const { pathname } = useLocation();
  const reference: { name: string; path: string }[] =
    props.type.editor?.table?.reference;
  const header = props.type.editor.table.fieldsToShow;
  const showHeader = props.type.editor.table.showHeader;
  const actionCell = props.type.editor.table.actionCell;
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showEditModalData, setShowEditModalData] = useState<any>();
  const [element, setElement] = useState<any[]>();
  const location = useLocation();
  let pathstart = location.pathname;
  const { pathName, pathId } = parseDashboardDetailsPath(pathstart);
  const [loading, setLoading] = useState<boolean>(true);
  const globalState = store.getState();
  const allEntity = parseDataFromGlobalContext(globalState.rootContext.apiDocs);
  const dispatch = useDispatch();
  const findEntity = allEntity
    ? allEntity.find((entity) => entity.name === props.type.editor.path)
    : null;
  const curatorsCompanyEntity = allEntity
    ? allEntity.find((entity) => entity.name === "curator_companies")
    : null;
  const [formCuratorsCompanies, setFormCuratorsCompanies] = useState({});
  const [showModalCreate, setShowModalCreate] = useState<boolean>(false);
  const handleShowModalCreate = () => {
    setShowModalCreate(!showModalCreate);
  };
  const takeData = (type: typeField, data: any) => {
    const value = (type.field && data[type.field]) || null;
    if (type.reference) {
      const allReference = referenceArrayResult[type.reference];
      return allReference
        ? allReference.find((reference) => reference["@id"] === value)
        : null;
    }
    return value;
  };
  const [referenceArrayResult, setReferenceArrayResult] = useState([]);

  const initialize = async () => {
    setLoading(true);
    const arrayOfId: number[] = props.data.map((iri) => takeIdFromIri(iri));
    const params = { params: { id: arrayOfId } };
    await apiCall(HTTPMethod.GET, `/${path}`, params)
      .then((response) => {
        saveNumberOfElementOnStore(
          pathname,
          props.name,
          response.data[hydra.TOTAL]
        );
        const result = response.data[hydra.MEMBER];
        setElement(result);
        if (result.length > 0 && reference) {
          reference.forEach((el) => {
            const allReferenceIri = result.map((e) => e[el.name]);
            getReference(
              eliminateDuplicates(allReferenceIri),
              el.path,
              el.name
            );
          });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };
  const getReference = async (
    iriData: string | string[],
    path: string,
    name: string
  ) => {
    const filterId: any =
      typeof iriData === "string"
        ? takeIdFromIri(iriData)
        : iriData.map((iri) => takeIdFromIri(iri));
    const params = { params: { id: filterId } };
    await apiCall(HTTPMethod.GET, `/${path}`, params)
      .then((response) => {
        setReferenceArrayResult({
          ...referenceArrayResult,
          [name]: response.data[hydra.MEMBER],
        });
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };
  const deleteElement = async (id: number) => {
    await apiCall(HTTPMethod.DELETE, `/${path}/${id}`)
      .then(() => {
        initialize();
      })
      .catch((err) => console.log(err));
  };
  const createCuratorCompanies = async (value) => {
    const defaultObj = {
      companies: `/api/companies/${pathId}`,
    };
    try {
      let res = await apiCall(HTTPMethod.POST, `/curator_companies`, {
        ...value,
        ...defaultObj,
      });
      setShowModal(false);
      setShowEditModal(false);
      setShowEditModalData(null);
      setFormCuratorsCompanies({});
      props.setValue(props.nameField, null, "makeGet");
      dispatch({
        type: MessagesReducerTypeActions.SET,
        payload: {
          type: MessageTypeEnum.SUCCESS,
          message: intl.formatMessage({ id: "linkCuratorsSuccess" }),
        },
      });
      if(value.main){
        handleStore(res.data, {
          pathName,
          pathId,
          formField: "mainCurator",
        });
      }

    } catch {
      dispatch({
        type: MessagesReducerTypeActions.SET,
        payload: {
          type: MessageTypeEnum.DANGER,
          message: intl.formatMessage({ id: "linkCuratorsError" }),
        },
      });
    }


  };
  const patchCuratorCompanies = async (id, value) => {
    try {
      await CuratorCompanyControllerApi.patch(id, {positionCode: value.positionCode})
      setShowModal(false);
      setShowEditModal(false);
      setShowEditModalData(null);
      setFormCuratorsCompanies({});
      props.setValue(props.nameField, null, "makeGet");
      dispatch({
        type: MessagesReducerTypeActions.SET,
        payload: {
          type: MessageTypeEnum.SUCCESS,
          message: intl.formatMessage({ id: "patchCuratorsSuccess" }),
        },
      });
    } catch {
      dispatch({
        type: MessagesReducerTypeActions.SET,
        payload: {
          type: MessageTypeEnum.DANGER,
          message: intl.formatMessage({ id: "patchCuratorsError" }),
        },
      });
    }


  };
  const handleChange = async (name, data, id) => {
    try {
      let res = await apiCall(HTTPMethod.PATCH, `/${path}/${id}`, {
        [name]: data,
      });
      initialize();
      // setdetailData(data.data)
      dispatch({
        type: MessagesReducerTypeActions.SET,
        payload: {
          type: MessageTypeEnum.SUCCESS,
          message: intl.formatMessage({ id: "SaveSuccess" }),
        },
      });
      if(path == 'curator_companies'){
        handleStore(res.data, {
          pathName,
          pathId,
          formField: "mainCurator",
        });
      }
    } catch {
      dispatch({
        type: MessagesReducerTypeActions.SET,
        payload: {
          type: MessageTypeEnum.DANGER,
          message: intl.formatMessage({ id: "SaveError" }),
        },
      });
    }
  };
  useEffect(
    () => {
      if (props.data.length > 0) {
        initialize();
      } else {
        setLoading(false);
      }
    },
    // eslint-disable-next-line
    [props.data]
  );
  const filteredFields = (fieldsToFilter) => {
    return Object.keys(fieldsToFilter)
      .filter((key) => fieldsToFilter[key]?.list?.post)
      .reduce((obj, key) => {
        obj[key] = fieldsToFilter[key];
        return obj;
      }, {});
  };

  return (
    <div className="many-to-many-complex card card-xl-stretch bg-transparent">
      <div className="card-body py-3">
        <div className="table-responsive">
          {loading ? (
            <Skeleton></Skeleton>
          ) : (
            <>
              <div className="d-flex justify-content-center w-100 p-4">
                {props.nameField === DynamicComponentNameFields.CompanyCurators && (
                  <CustomButton
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                      {intl.formatMessage({ id: 'addcurators_companies' })}
                  </CustomButton>
                )}
              </div>
              <table className="table align-middle gs-0 gy-5">
                <>
                  <thead>
                    <tr>
                      {element &&
                        element.length > 0 &&
                        Object.keys(header).map((key, index) => (
                          <th
                            key={`${key}-${index}-${generateUniqueId()}`}
                            className=" p-0 min-w-50px"
                          >
                            <p className="header-mmTable">
                              {showHeader &&
                                intl.formatMessage({ id: `${key}` })}
                            </p>
                          </th>
                        ))}
                    </tr>
                  </thead>
                  <tbody>
                    {element && element.length > 0 ? (
                      element.map((el) => {
                        return (
                          <tr key={`${el}+${generateUniqueId()}`}>
                            {Object.keys(header).map((key, index) => {
                              const typeCell = header[key];
                              return (
                                typeCell && (
                                  <Cells
                                    key={`${key}-${index}-${generateUniqueId()}`}
                                    setValue={(name, data) => {
                                      if (data !== el[typeCell.field]) {
                                        handleChange(name, data, el.id);
                                      }
                                    }}
                                    typeCells={typeCell}
                                    data={takeData(typeCell, el)}
                                  ></Cells>
                                )
                              );
                            })}
                            {!actionCell === false && (
                            <>
                              <td className="actions-cell">
                                <div
                                  className="icon-wrapper"
                                  onClick={() =>{
                                        // TODO
                                        setShowEditModalData(el);
                                        setShowEditModal(true);
                                      }
                                  }
                                >
                                  <i className="fa-solid fa-pencil" />
                                </div>
                              </td>
                              <td className="actions-cell">
                                <div
                                  className="icon-wrapper"
                                  onClick={() =>
                                    window.confirm(
                                      intl.formatMessage({
                                        id: "window.delete",
                                      })
                                    ) && deleteElement(el.id)
                                  }
                                >
                                  <i className="fa-solid fa-trash-can" />
                                </div>
                              </td>
                              </>
                            )}
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="d-block w-100">
                        <div className="empty-element w-100 ">
                          <i className="fa-regular fa-file"></i>
                          <p>{intl.formatMessage({ id: "Empty.elements" })}</p>
                        </div>
                      </tr>
                    )}

                    {findEntity && (
                      <tr>
                        <FormModal
                          entity={findEntity}
                          show={showModalCreate}
                          type="post"
                          handleClose={() => setShowModalCreate(false)}
                        ></FormModal>
                      </tr>
                    )}
                  </tbody>
                </>
              </table>
                {
                <Modal
                  size={"lg"}
                  show={showEditModal}
                  onHide={() => setShowEditModal(false)}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>
                      {intl.formatMessage({
                        id: "edit" + "curators_companies",
                      })}
                    </Modal.Title>{" "}
                  </Modal.Header>
                  <Modal.Body>
                    <Form
                      data={formCuratorsCompanies}
                      nameEntity="curators_companies"
                      fields={
                          {
                            positionCode: curatorsCompanyEntity?.fields.positionCode
                          }
                      }
                      onSave={(value) => {
                        setFormCuratorsCompanies(value);
                        patchCuratorCompanies(showEditModalData?.id, value);
                      }}
                    ></Form>
                  </Modal.Body>
                </Modal>
              }
              {
                <Modal
                  size={"lg"}
                  show={showModal}
                  onHide={() => setShowModal(false)}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>
                      {intl.formatMessage({
                        id: "add" + "curators_companies",
                      })}
                    </Modal.Title>{" "}
                  </Modal.Header>
                  <Modal.Body>
                    <Form
                      data={formCuratorsCompanies}
                      nameEntity="curators_companies"
                      fields={
                        curatorsCompanyEntity?.fields &&
                        filteredFields(curatorsCompanyEntity?.fields)
                      }
                      onSave={(value) => {
                        setFormCuratorsCompanies(value);
                        createCuratorCompanies(value);
                      }}
                    ></Form>
                  </Modal.Body>
                </Modal>
              }
            </>
          )}
        </div>
      </div>
    </div>
  );
}

interface CellsProps {
  typeCells: {
    type: string;
    field?: string;
    reference?: string;
    topFields?: string[];
    bottomFields?: string[];
    icon?: string;
    translate?: string;
    mainField?: string;
    name?: string;
    buttons?: { icon: string; action: string }[];
  };
  setValue?: (name, data) => void;
  data?: any;
  actions?: (string, number?) => void;
}
const Cells: FC<CellsProps> = (props) => {
  const intl = useIntl();
  const { type, topFields, bottomFields, mainField, translate, field } =
    props.typeCells;
  const value = props.data;
  const [showInput, setShowInput] = useState<boolean>(false);

  const { hash } = useLocation();
  const navigate = useNavigate();
  const handleClick = () => {
    if (value) {
      const hashPath = value["@id"].split("/api/").pop();
      if (!hash) {
        navigate(`#${hashPath}`);
      }
    }
  };
  switch (type) {
    case "status":
      return (
        <td>
          <div
            onClick={() => {
              return props?.setValue && props?.setValue(field, !value);
            }}
            className={value === true ? "mainFieldActive" : "mainFieldDisable"}
          />
        </td>
      );
    case "credential":
      return (
        <td className="credential" onClick={() => handleClick()}>
          <div className="d-flex">
            <div className="symbol symbol-45px symbol-circle">
              <span className="symbol-label bg-light-primary text-primary fs-6 fw-bolder">
                {value?.firstName?.charAt(0)}
                {value?.lastName?.charAt(0)}
              </span>
            </div>
            <div className="d-flex flex-column ps-5">
              {topFields && value && (
                <span className="topFields">
                  {topFields.map((field, index) => (
                    <div key={`${field}-${index}`}>{value[field]} </div>
                  ))}
                </span>
              )}
              {bottomFields && value && (
                <span className="bottomFields">
                  {bottomFields.map((field) => value[field])}
                </span>
              )}
            </div>
          </div>
        </td>
      );
    case "inputInline":
      return (
        <td className="showReference">
          <>
            {showInput ? (
              <BasicInput
                props={{
                  data: value,
                  setShowInput: setShowInput,
                  name: field,
                  setValue: props.setValue,
                }}
                typeBasicInput={"text"}
              ></BasicInput>
            ) : (
              <span onClick={() => setShowInput(true)}>
                {value ? value : " - "}
              </span>
            )}
          </>
        </td>
      );
    case "showReference":
      return (
        <td className="showReference">
          {mainField && (
            <span>{value && value[mainField] ? value[mainField] : "-"}</span>
          )}
        </td>
      );
    case "badge":
      return (
        <td>
          <span
            className="badge mr-4
             badge-light-primary"
          >
            {value}
          </span>
          {translate && (
            <span className="label-mmTable">
              {formatFirstUppercase(
                intl.formatMessage({
                  id: `${translate}.${formatUppercase(value)}`,
                })
              )}
            </span>
          )}
        </td>
      );
    case "text":
      return <td>{value ? value : "-"}</td>;
    case "link":
      return (
          <td>
            {value ? (
                <a
                    href={value}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                  {'Vedi annuncio'}
                </a>
            ) : "-"}
          </td>
      );
    case "date":
      return <td>{value ? moment(value).format("DD/MM/YYYY") : "-"}</td>;
  }
};
