import { useDispatch } from "react-redux";
import {
  MessagesReducerTypeActions,
  MessageTypeEnum,
} from "../redux/stores/message/messageReducer";

const useToast = () => {
  const dispatch = useDispatch();

  const showToast = (type: MessageTypeEnum, message: string) => {
    dispatch({
      type: MessagesReducerTypeActions.SET,
      payload: {
        type: type,
        message: message,
      },
    });
  };

  const showSuccessToast = (message: string) => {
    showToast(MessageTypeEnum.SUCCESS, message);
  };
  const showErrorToast = (message: string) => {
    showToast(MessageTypeEnum.DANGER, message);
  };
  return {
    showToast: showSuccessToast,
    showErrorToast: showErrorToast,
  };
};

export default useToast;
