import { FormattedMessage } from "react-intl";
import { OverlayTrigger, Popover } from "react-bootstrap";

export function CardIntervals({ code, description, voteMin, voteMax, fixed, score }) {
    const maxLength = 95;
    const isTruncated = description.length > maxLength;
    const truncatedText = isTruncated ? description.substring(0, maxLength) + "…" : description;

    return (
        <div
            className="border border-secondary border-radius-1 fw-normal bg-white p-2 d-flex justify-content-between riepilogo-col">

            {/* Colonna Descrizione*/}
            <div className="col-8 text-truncate">
                {isTruncated ? (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Popover id={`popover-description-${code}`}>
                                <Popover.Body>{description}</Popover.Body>
                            </Popover>
                        }
                    >
                        <p className="m-0 text-wrap">
                            <span className="text-primary fw-bold">{code}</span> - {truncatedText}
                        </p>
                    </OverlayTrigger>
                ) : (
                    <p className="m-0 text-wrap">
                        <span className="text-primary fw-bold">{code}</span> - {description}
                    </p>
                )}
            </div>

            {/* Colonna Voti */}
            <div className="col-4 text-nowrap text-end vote-box">
                <p className="m-0 fw-bold text-primary">
                    <FormattedMessage id="Wizard.riepilogo.vote"/>
                    <br/>
                    <span className="vote-values">
                        {fixed ? `[${score}]` : `[min: ${voteMin} - max: ${voteMax}]`}
                    </span>
                </p>
            </div>
        </div>
    );
}
