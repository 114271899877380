import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Icon } from "../../../../models/Icon/Icon";
import { EIconType } from "../../../../utils/helper";

export default function WizardList({value = [], onChange, label, displayFormat = "default",
}: {
    value: { code: string; description: string }[];
    onChange?: (item: { code: string; description: string }) => void;
    label?: {
        title: string;
        note: string;
    };
    displayFormat?: "default" | "descriptionOnly";
}) {

    const [selectedCode, setSelectedCode] = useState<string | null>(null);

    const handleClick = (item: { code: string; description: string }) => {
        setSelectedCode(item.code);
        onChange && onChange(item);
    };

    return (
        <div>
            <div className="d-flex align-items-center gap-4 py-2">
                <Icon
                    nameIcon="format_list_bulleted"
                    typeIcon={"material-icons-outlined"}
                    type={EIconType.MATERIAL}
                />
                {label && (
                    <p className="m-0">
                        {label.title && (
                            <span className="">
                                <FormattedMessage id={label.title} />
                            </span>
                        )}
                        {label.note && (
                            <span>
                                <FormattedMessage id={label.note} />
                            </span>
                        )}
                    </p>
                )}
            </div>
            <div
                className="w-full bg-white py-2 px-4 d-flex flex-column gap-4 rounded-3"
                style={{ maxHeight: "577px", overflowY: "auto" }}
            >
                {value.map((item) => (
                    <div
                        key={item.code}
                        onClick={() => handleClick(item)}
                        className={`list-item ${selectedCode === item.code ? "selected" : ""}`}
                        style={{
                            padding: "5px",
                            borderRadius: "4px",
                            cursor: "pointer",
                            backgroundColor: selectedCode === item.code ? "#d3d3d3" : "transparent",
                            color: "black",
                        }}
                    >
                        {displayFormat === "default" ? `${item.code} - ${item.description}` : item.description}
                    </div>
                ))}
            </div>
        </div>
    );
}
