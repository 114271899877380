import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { Icon } from "../../../../models/Icon/Icon";
import { EIconType } from "../../../../utils/helper";
import Select from "react-select";

export const WizardSelect = ({
  name,
  value = [],
  options = [],
  placeholder,
  label,
  onChange,
  isMulti = false,
}: {
  name: string;
  value: { value: string; label: string }[];
  options?: { value: string; label: string }[];
  onChange?: (any) => void;
  label?: {
    title: string;
    note?: string;
  };
  placeholder?: string;
  isMulti?: boolean;
}) => {
  const defaultPlaceholder = "Wizard.find";
  const intl: IntlShape = useIntl();
  return (
    <div>
      <div className="d-flex align-items-center gap-4 py-2">
        <Icon
          nameIcon="format_list_bulleted"
          typeIcon={"material-icons-outlined"}
          className="icon-editor"
          type={EIconType.MATERIAL}
        />
        {label && (
          <p className="m-0 ">
            {label.title && (
              <span className="">
                <FormattedMessage id={label.title} />
              </span>
            )}
            {label.note && (
              <span>
                <FormattedMessage id={label.note} />
              </span>
            )}
          </p>
        )}
      </div>
      <Select
        placeholder={intl.formatMessage({
          id: placeholder || defaultPlaceholder,
        })}
        isMulti={isMulti}
        name={name}
        options={value}
        onChange={(value) => {
          onChange && value && onChange(value);
        }}
      />
    </div>
  );
};
