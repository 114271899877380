import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import { Icon } from "../../../../models/Icon/Icon";
import { EIconType } from "../../../../utils/helper";
import { RelevanceModifierEnum } from "../../../../enums/RelevanceModifierEnum.ts";

interface WizardRelevanceProps {
    value: RelevanceModifierEnum;
    onChange?: (value: RelevanceModifierEnum) => void;
    label?: { title: string; note?: string };
    className?: string;
}

export default function WizardRelevance({value, onChange, label, className = "",}: WizardRelevanceProps) {

    const buttonClass = (selected: boolean) =>
        clsx("flex-fill btn", selected ? "btn-primary" : "border border-secondary");

    return (
        <div className={className}>
            <div className="d-flex align-items-center gap-4 py-2">
                {label && (
                    <>
                        <Icon
                            nameIcon={"sentiment_very_satisfied"}
                            typeIcon="material-icons-outlined"
                            type={EIconType.MATERIAL}
                        />
                        <p className="m-0 fw-bold">
                            {label.title && <span><FormattedMessage id={label.title} /></span>}
                            {label.note && <span><FormattedMessage id={label.note} /></span>}
                        </p>
                    </>
                )}
            </div>

            <div className="d-flex gap-2 fw-bold">
                {(Object.values(RelevanceModifierEnum) as RelevanceModifierEnum[]).map((relevance) => (
                    <button
                        key={relevance}
                        className={buttonClass(value === relevance)}
                        onClick={() => onChange?.(relevance)}
                    >
                        <FormattedMessage id={`Wizard.relevance.${relevance}`} />
                    </button>
                ))}
            </div>
        </div>
    );
}
