export enum RelevanceKeysEnum {
    SALES_LESS_MILLION = "SALES_LESS_MILLION",
    SALES_BETWEEN_1_AND_5_MILLION = "SALES_BETWEEN_1_AND_5_MILLION",
    SALES_BETWEEN_5_AND_10_MILLION = "SALES_BETWEEN_5_AND_10_MILLION",
    SALES_OVER_10_MILLION = "SALES_OVER_10_MILLION",

    EMPLOYEES_LESS_25 = "EMPLOYEES_LESS_25",
    EMPLOYEES_BETWEEN_26_AND_100 = "EMPLOYEES_BETWEEN_26_AND_100",
    EMPLOYEES_BETWEEN_101_AND_500 = "EMPLOYEES_BETWEEN_101_AND_500",
    EMPLOYEES_OVER_500 = "EMPLOYEES_OVER_500",

    CAPITAL_LESS_MILLION = "CAPITAL_LESS_MILLION",
    CAPITAL_BETWEEN_1_AND_5_MILLION = "CAPITAL_BETWEEN_1_AND_5_MILLION",
    CAPITAL_BETWEEN_5_AND_10_MILLION = "CAPITAL_BETWEEN_5_AND_10_MILLION",
    CAPITAL_OVER_10_MILLION = "CAPITAL_OVER_10_MILLION",
}
