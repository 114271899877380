import { useEffect, useMemo, useState } from "react";
import { HeaderWrapper } from "../../models/header/HeaderWrapper";
import { GlobalSidebar } from "../../models/sidebar/GlobalSidebar";
import { FormattedMessage, useIntl } from "react-intl";
import Stepper from "../../models/stepper/Stepper";
import { takeIdFromIri } from "../../utils/helper";
import { useSelector } from "react-redux";
import { CombineReducersState } from "../../redux/store";
import { GroupControllerApi } from "../../apis/groupController";
import useToast from "../../hooks/useToast";
import { GroupImportConfigurationDto } from "../../apis/entities";
import CodeAtecoSteps from "./steps/CodeAteco";
import { ProcedureSteps } from "./steps/Procedure";
import { GeographicalAreaSteps } from "./steps/GeographicalArea";
import AltriParametriSteps from "./steps/AltriParametri";
import RiepilogoSteps from "./steps/Riepilogo";
import WizardNavigation from "./components/WizardNavigation.tsx";

export enum StepsName {
  CODICE_ATECO = "codice-ateco",
  PROCEDURA = "procedura",
  AREA_GEO = "area-geografica",
  ALTRI_PAR = "altri-parametri",
  RIEPILOGO = "riepilogo",
}

export interface PropsStepI {
  groupConfiguration?: GroupImportConfigurationDto;
  globalState: { [key: string]: any };
  setGlobalState: React.Dispatch<React.SetStateAction<PropsStepI["globalState"]>>;
}

export default function Wizard() {
  const intl = useIntl();
  const { showErrorToast, showToast } = useToast();

  const userData = useSelector(
      (state: CombineReducersState) => state.userReducer.userData
  )!;
  const group = userData.groupUser?.[0]?.group["@id"];

  const dataSteps: StepsName[] = [
    StepsName.CODICE_ATECO,
    StepsName.PROCEDURA,
    StepsName.AREA_GEO,
    StepsName.ALTRI_PAR,
    StepsName.RIEPILOGO,
  ];

  const [globalState, setGlobalState] = useState<PropsStepI["globalState"]>({});
  const [currentStep, setCurrentStep] = useState<string>(StepsName.CODICE_ATECO);
  const [groupConfiguration, setGroupConfiguration] = useState<GroupImportConfigurationDto>();
  const [groupId, setGroupId] = useState<string | null>(null);

  // recupero configurazioni
  useEffect(() => {
    GroupControllerApi.getGroupById(takeIdFromIri(group) + "")
        .then((response) => {
          console.log("✅ Group Configuration Response:", response.data);
          setGroupId(String(response.data.id));
          const config = response.data.importConfiguration;

          setGroupConfiguration(config);

          // Verifica se la configurazione è vuota o assente
          if (!config || Object.keys(config).length === 0) {
            console.log("⚠️ Nessuna configurazione trovata, inizia da step 1.");
            setCurrentStep(StepsName.CODICE_ATECO);
          } else {
            console.log("✅ Configurazione trovata, visualizza riepilogo.");
            setCurrentStep(StepsName.RIEPILOGO);
          }
        })
        .catch(() => {
          showErrorToast(intl.formatMessage({ id: "Generic.error" }));
        });
  }, []);

  useEffect(() => {
    if (groupConfiguration) {
      // setGlobalState(getInitialGlobalState(groupConfiguration));
      // todo remove debug
      const initialState = getInitialGlobalState(groupConfiguration);
      console.log("🗄️ Loaded Configuration:", groupConfiguration);
      console.log("🌍 Initial Global State:", initialState);
      setGlobalState(initialState);
    }
  }, [groupConfiguration]);

  const getInitialGlobalState = (config: GroupImportConfigurationDto) => ({
    atecoCodeRelevancesSelected: config.atecoCodeRelevances?.map(item => ({
      code: item.code,
      score: item.score,
      fixed: item.fixed
    })) || [],
    typologyRelevancesSelected: config.typologyRelevances?.map(item => ({
      code: item.code,
      value: item.value
    })) || [],
    locationRelevancesSelected: config.locationRelevances?.map(item => ({
      code: item.code,
      value: item.value
    })) || [],
    salesRangesRelevancesSelected: config.salesRangesRelevances?.map(item => ({
      code: item.code,
      value: item.value
    })) || [],
    employeeRangesRelevancesSelected: config.employeeRangesRelevances?.map(item => ({
      code: item.code,
      value: item.value
    })) || [],
    capitalRangesRelevancesSelected: config.capitalRangesRelevances?.map(item => ({
      code: item.code,
      value: item.value
    })) || []
  });

  const isSummaryStep = currentStep === StepsName.RIEPILOGO;
  const [isSaving, setIsSaving] = useState(false);

  // salva configurazioni nello step riepilogo
  const handleSave = async () => {
    if (!groupId) {
      console.error("❌ Errore: ID del gruppo non disponibile.");
      return;
    }

    setIsSaving(true);

    const payload = {
      strategy: 'defaul',
      atecoCodeRelevances: globalState.atecoCodeRelevancesSelected,
      typologyRelevances: globalState.typologyRelevancesSelected,
      locationRelevances: globalState.locationRelevancesSelected,
      salesRangesRelevances: globalState.salesRangesRelevancesSelected,
      employeeRangesRelevances: globalState.employeeRangesRelevancesSelected,
      capitalRangesRelevances: globalState.capitalRangesRelevancesSelected,
    };

    try {
      await GroupControllerApi.updateGroupConfiguration(groupId, payload);
      console.log("✅ Configurazione salvata con successo!", payload);
      showToast("Configurazione salvata con successo!");
    } catch (error) {
      console.error("❌ Errore nel salvataggio della configurazione:", error);
      showErrorToast("Errore nel salvataggio della configurazione");
    } finally {
      setIsSaving(false);
    }
  };

  const canProceedToNextStep = useMemo(() => {
    switch (currentStep) {
      case StepsName.CODICE_ATECO:
        return globalState.atecoCodeRelevancesSelected?.length > 0;
      case StepsName.PROCEDURA:
        return globalState.typologyRelevancesSelected?.length > 0;
      case StepsName.AREA_GEO:
      case StepsName.ALTRI_PAR:
      case StepsName.RIEPILOGO:
        return true;
      default:
        return false;
    }
  }, [currentStep, globalState]);

  const prevButtonEnable = useMemo(() => currentStep !== StepsName.CODICE_ATECO, [currentStep]);
  const nextButtonEnable = canProceedToNextStep;

  const getNextStep = () => {
    const index = dataSteps.indexOf(currentStep as StepsName);
    return index !== -1 && dataSteps[index + 1] ? dataSteps[index + 1] : null;
  };

  const getPreviousStep = () => {
    const index = dataSteps.indexOf(currentStep as StepsName);
    return index !== -1 && dataSteps[index - 1] ? dataSteps[index - 1] : null;
  };

  const handlePrevSteps = () => {
    const prevStep = getPreviousStep();
    if (prevStep) setCurrentStep(prevStep);
  };

  const handleNextSteps = () => {
    const nextStep = getNextStep();
    if (nextStep) setCurrentStep(nextStep);
  };

  const renderStep = () => {
    const stepProps = { groupConfiguration, globalState, setGlobalState };

    switch (currentStep) {
      case StepsName.CODICE_ATECO:
        return <CodeAtecoSteps {...stepProps} />;
      case StepsName.PROCEDURA:
        return <ProcedureSteps {...stepProps} />;
      case StepsName.AREA_GEO:
        return <GeographicalAreaSteps {...stepProps} />;
      case StepsName.ALTRI_PAR:
        return <AltriParametriSteps {...stepProps} />;
      case StepsName.RIEPILOGO:
        return <RiepilogoSteps {...stepProps} setCurrentStep={setCurrentStep} />;
      default:
        return null;
    }
  };

  return (
      <>
        <HeaderWrapper />
        <GlobalSidebar />
        <div className="app-wrapper wizard flex-column flex-row-fluid">
          <div>
            <div className="p-10">
              <h4>
                <FormattedMessage id="Wizard.title" />
              </h4>
            </div>
            <div className="px-20">
              <Stepper steps={dataSteps} currentStep={currentStep} onChangeStep={setCurrentStep} />
            </div>
            <div className="d-flex justify-content-center w-full px-10 gap-4">
              {renderStep()}
            </div>
            <WizardNavigation
                isSummaryStep={isSummaryStep}
                isSaving={isSummaryStep ? isSaving : undefined}
                onNext={handleNextSteps}
                onSave={isSummaryStep ? handleSave : undefined}
                onPrev={handlePrevSteps}
                prevButtonEnable={prevButtonEnable}
                nextButtonEnable={nextButtonEnable}
            />
          </div>
        </div>
      </>
  );
}
