import { FormattedMessage } from "react-intl";

export function CardRegioni({ regione, province, relevance }) {
    return (
        <div className="border border-secondary border-radius-1 fw-normal bg-white p-2 d-flex justify-content-between riepilogo-col">
            <p className="m-0">
                {regione}
                {province.length > 0 && ` / ${province.join(" - ")}`}
            </p>
            <p className="m-0 fw-bold text-success">
                <FormattedMessage id={`Wizard.relevance.${relevance}`} />
            </p>
        </div>
    );
}
