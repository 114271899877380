import { AxiosResponse } from "axios";
import { HTTPMethod } from "../utils/helper";
import { apiCall } from "../utils/serviceCall";
import {Group, GroupImportConfigurationDto} from "./entities";

/**
 * API Controller for Group entity
 */
export class GroupControllerApi {
  /**
   * Retrieves a group entity by id
   * @param id - Id of group to retrieve
   * @param params - Optional parameters
   * @returns The found group
   */
  static async getGroupById(
    id: string,
    params?: any,
  ): Promise<AxiosResponse<Group>> {
    return apiCall(HTTPMethod.GET, `/groups/${id}`, { params: params });
  }

  /**
   * Updates a group's configuration
   * @param id - The ID of the group to update
   * @param config - The configuration DTO matching `GroupImportConfigurationDto`
   * @returns Axios response with updated group data
   */
  static async updateGroupConfiguration(
      id: string,
      config: GroupImportConfigurationDto
  ): Promise<AxiosResponse<Group>> {
    return apiCall(HTTPMethod.PUT, `/groups/${id}/configuration`, config);
  }
}
