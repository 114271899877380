import { Icon } from "../../../models/Icon/Icon";
import { EIconType } from "../../../utils/helper";
import { FormattedMessage } from "react-intl";
import WizardRelevance from "../components/WizardRelevance/WizardRelevance.tsx";
import { PropsStepI } from "../Wizard";
import { RelevanceModifierEnum } from "../../../enums/RelevanceModifierEnum.ts";
import { RelevanceKeysEnum } from "../../../enums/RelevanceKeysEnum.ts";
import {parametersMapping} from "../../../json/parametersMapping.ts";

export default function AltriParametriSteps({ globalState, setGlobalState }: PropsStepI) {

  /** Modifica del valore di rilevanza per un parametro specifico */
  const handleChange = (key: RelevanceKeysEnum, newValue: RelevanceModifierEnum, category: string) => {
    setGlobalState((prev) => {
      const categoryKey = `${category}RangesRelevancesSelected`;

      const updatedCategory = prev[categoryKey] ? [...prev[categoryKey]] : [];

      const filteredCategory = updatedCategory.filter((param) => param.code !== key);

      filteredCategory.push({ code: key, value: newValue.toString() });

      return {
        ...prev,
        [categoryKey]: filteredCategory,
      };
    });
  };

  /** Raggruppiamo i parametri per titolo */
  const groupedParameters = parametersMapping.reduce((acc, param) => {
    if (!acc[param.category]) {
      acc[param.category] = [];
    }
    acc[param.category].push(param);
    return acc;
  }, {} as Record<string, typeof parametersMapping>);

  return (
      <div className="d-flex flex-column flex-fill gap-4">
        {Object.entries(groupedParameters).map(([category, params]) => (
            <div key={category} className="d-flex flex-column gap-2">
              {/* Titolo del gruppo */}
              <div className="d-flex gap-2 align-items-center">
                <Icon typeIcon="material-icons-outlined" nameIcon={params[0].icon} type={EIconType.MATERIAL} />
                <p className="m-0 fw-bold">
                  <FormattedMessage id={`Wizard.altri-parametri.${category}`} />
                </p>
              </div>

              {/* Opzioni sotto il titolo */}
              {params.map((parameter) => {
                // Determinare la categoria corretta per il globalState
                const categoryKey = `${parameter.category}RangesRelevancesSelected`;

                // Ottenere il valore corrente della rilevanza
                const currentRelevance =
                    (globalState[categoryKey] ?? []).find((param) => param.code === parameter.key)?.value ||
                    RelevanceModifierEnum.MEDIUM;

                return (
                    <div key={parameter.key} className="d-flex gap-2 bg-white align-items-center rounded-2 p-2">
                      <p className="m-0 w-25 fw-bold">
                        <FormattedMessage id={`Wizard.altri-parametri.${parameter.key}`} />
                      </p>
                      <WizardRelevance
                          className="w-75"
                          value={currentRelevance}
                          onChange={(newValue) => handleChange(parameter.key, newValue, parameter.category)}
                      />
                    </div>
                );
              })}
            </div>
        ))}
      </div>
  );
}
