import { FormattedMessage } from "react-intl";
import clsx from "clsx";
import {Icon} from "../../../models/Icon/Icon.tsx";
import {EIconType} from "../../../utils/helper.tsx";


interface WizardNavigationProps {
    isSummaryStep: boolean;
    isSaving?: boolean;
    onNext: () => void;
    onSave?: () => void;
    onPrev: () => void;
    prevButtonEnable: boolean;
    nextButtonEnable: boolean;
}

export default function WizardNavigation({isSummaryStep, isSaving, onNext, onSave, onPrev, prevButtonEnable, nextButtonEnable}: WizardNavigationProps) {
    return (
        <div className="d-flex align-items-center justify-content-between p-10">
            <button
                onClick={onPrev}
                className={clsx("button-normalize", prevButtonEnable ? "button-primary-extended" : "button-disabled-extended")}
                disabled={!prevButtonEnable}
            >
                <Icon type={EIconType.MATERIAL} typeIcon="material-icons-outlined" nameIcon="arrow_left" />
                <FormattedMessage id="Wizard.prev" />
            </button>

            <button
                onClick={isSummaryStep ? onSave : onNext}
                className={clsx(
                    "button-normalize text-white",
                    isSummaryStep || isSaving
                        ? "button-success"
                        : nextButtonEnable
                            ? "button-primary-extended"
                            : "button-disabled-extended"
                )}
                disabled={isSummaryStep ? isSaving : !nextButtonEnable}
                style={isSummaryStep || isSaving ? { background: "#82BA00" } : {}}
            >
                {isSummaryStep ? (isSaving ? "Salvataggio in corso..." : "Salva") : <FormattedMessage id="Wizard.next" />}
                <Icon type={EIconType.MATERIAL} typeIcon="material-icons-outlined" nameIcon="arrow_right" />
            </button>
        </div>
    );
}
