import { useEffect, useMemo, useState } from "react";
import { WizardSelect } from "../components/WizardSelect/WizardSelect";
import { FormattedMessage } from "react-intl";
import { EIconType } from "../../../utils/helper";
import { Icon } from "../../../models/Icon/Icon";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import WizardRelevance from "../components/WizardRelevance/WizardRelevance.tsx";
import { PropsStepI } from "../Wizard";
import { RelevanceModifierEnum } from "../../../enums/RelevanceModifierEnum.ts";
import {locationsData} from "../../../json/locationsData.ts";
import WizardList from "../components/WizardList/WizardList.tsx";


export function GeographicalAreaSteps({ globalState, setGlobalState, groupConfiguration }: PropsStepI) {
  /** Stato per selezione regione, province e rilevanza */
  const [selectedRegione, setSelectedRegione] = useState<{ value: string; label: string } | null>(null);
  const [selectedProvincia, setSelectedProvincia] = useState<string | null>(null);
  const [selectedRelevance, setSelectedRelevance] = useState<RelevanceModifierEnum>(RelevanceModifierEnum.MEDIUM);
  const [resetKey, setResetKey] = useState(0);

  /** Sincronizzazione iniziale con globalState */
  useEffect(() => {
    if (groupConfiguration && !globalState.locationRelevancesSelected) {
      console.log("🗄️ Group Configuration - Location:", groupConfiguration.locationRelevances);
      setGlobalState((prev) => ({
        ...prev,
        locationRelevancesSelected: groupConfiguration.locationRelevances || [],
      }));
    }
  }, [groupConfiguration, setGlobalState]);

  /** Opzioni per la select delle Regioni */
  const regionOptions = useMemo(() => {
    return locationsData.map((regione) => ({
      value: regione.label,
      label: regione.label
    }));
  }, []);

  /** Province filtrate in base alla regione selezionata */
  const provinceOptions = useMemo(() => {
    if (!selectedRegione) return [];
    const regione = locationsData.find((r) => r.label === selectedRegione.value);
    return regione ? regione.provinces.map((p) => ({ code: p, description: p })) : [];
  }, [selectedRegione]);

  /** Aggiunta di una nuova area geografica */
  const handleAdd = () => {
    if (!selectedRegione) return;

    const newEntry = {
      code: selectedProvincia || selectedRegione.value,
      value: selectedRelevance.toString(),
    };

    setGlobalState((prev) => {
      const currentAreas = prev.locationRelevancesSelected ?? [];

      const updatedAreas = currentAreas.some((item) => item.code === newEntry.code)
          ? currentAreas.map((item) => (item.code === newEntry.code ? newEntry : item))
          : [...currentAreas, newEntry];

      console.log("🔄 Updating locationRelevancesSelected:", updatedAreas);

      return { ...prev, locationRelevancesSelected: updatedAreas };
    });

    reset();

  };

  /** Rimozione di una regione dal globalState */
  const handleRemove = (codeToRemove: string) => {
    console.log("🗑️ Removing geographical area:", codeToRemove);
    setGlobalState((prev) => ({
      ...prev,
      locationRelevancesSelected: prev.locationRelevancesSelected.filter((entry) => entry.code !== codeToRemove),
    }));
  };

  /** Reset della selezione corrente */
  const reset = () => {
    setSelectedRegione(null);
    setSelectedProvincia(null);
    setSelectedRelevance(RelevanceModifierEnum.MEDIUM);
    setResetKey(prevKey => prevKey + 1);
  };

  /** Verifica se il pulsante "Aggiungi" deve essere disabilitato */
  const isAddDisabled = useMemo(() => !selectedRegione, [selectedRegione]);

  return (
      <>
        <div className="col-7 flex-fill d-flex flex-column gap-10">
          {/* Selezione Regione */}
          <WizardSelect
              key={resetKey}
              name="regioni"
              value={regionOptions}
              label={{ title: "Wizard.regioni-label" }}
              onChange={(selected) => setSelectedRegione(selected)}
          />

          {/* Selezione Province (solo se Regione selezionata) */}
          {/*{selectedRegione && (*/}
          {/*    <WizardSelect*/}
          {/*        name="province"*/}
          {/*        value={selectedProvincia ? [{ value: selectedProvincia, label: selectedProvincia }] : []} */}
          {/*        options={provinceOptions.map((p) => ({ value: p.code, label: p.description }))} */}
          {/*        label={{ title: "Wizard.province-label" }}*/}
          {/*        onChange={(selected) => setSelectedProvincia(selected.value)}*/}
          {/*    />*/}
          {/*)}*/}
          {selectedRegione && provinceOptions.length > 0 && (
          <WizardList
              key={`province-${resetKey}`}
              value={provinceOptions}
              label={{ title: "Wizard.province-label", note: "" }}
              onChange={(selected) => setSelectedProvincia(selected.description)}
              displayFormat="descriptionOnly"/>
          )}

          {/* Selezione Rilevanza */}
          <WizardRelevance
              value={selectedRelevance}
              onChange={setSelectedRelevance}
              label={{ title: "Wizard.choose-relevance-label" }}
          />

          {/* Pulsante Aggiungi */}
          <button
              className="btn btn-default d-flex align-items-center gap-2 text-white w-fit"
              style={{ background: "#82BA00" }}
              onClick={handleAdd}
              disabled={isAddDisabled}
          >
            <Icon nameIcon="add" typeIcon="material-icons" type={EIconType.MATERIAL} />
            <FormattedMessage id="Wizard.add" />
          </button>
        </div>

        {/* Lista Aree Geografiche Selezionate */}
        <div className="col-5 flex-fill d-flex flex-column">
          <div className="d-flex align-items-center gap-4 py-2">
            <Icon nameIcon="bookmark_added" typeIcon="material-icons-outlined" type={EIconType.MATERIAL} />
            <p className="m-0">
              <FormattedMessage id="Wizard.region-added" />
            </p>
          </div>

          <div className="bg-white flex-fill rounded-3 py-2 px-4 overflow-auto d-flex flex-column gap-2" style={{ minWidth: "40%" }}>
            {(globalState.locationRelevancesSelected || []).map((added, index) => {
              const regione = locationsData.find((r) => r.provinces.includes(added.code));
              return (
                  <div key={index} className="d-flex rounded-2 align-items-center justify-content-between p-2"
                       style={{border: "1px solid #A2A5B5"}}>
                    <p className="m-0 ps-2" style={{fontWeight: 600, color: "#8F8F8F"}}>
                      {regione ? `${regione.label} / ${added.code}` : added.code}
                    </p>
                    <div className="d-flex align-item-center">
                      <p className="m-0 pe-1" style={{color: "#82BA00", fontWeight: 700}}>
                        <FormattedMessage id={`Wizard.relevance.${added.value}`}/>
                      </p>
                      <OverlayTrigger
                          key="bottom"
                          placement="bottom"
                          trigger="click"
                          overlay={
                            <Tooltip id="tooltip-top">
                              <div className="cursor-pointer" onClick={() => handleRemove(added.code)}>
                                <FormattedMessage id="Wizard.delete"/>
                              </div>
                            </Tooltip>
                          }
                      >
                        <div>
                          <Icon className="cursor-pointer" type={EIconType.MATERIAL} nameIcon="more_vert"
                                typeIcon="material-icons-outlined"/>
                        </div>
                      </OverlayTrigger>
                    </div>
                  </div>
              );
            })}
          </div>
        </div>
      </>
  );
}
