import React, { useMemo, useState } from "react";
import Messages from "./Messages";
import { useSelector } from "react-redux";
import { CombineReducersState } from "../../redux/store";
import {
  ETypeNotifications,
  HTTPMethod,
  getNotification,
} from "../../utils/helper";
import { useLocation, useNavigate } from "react-router-dom";
import { apiCall } from "../../utils/serviceCall";
import { useIntl } from "react-intl";
import clsx from "clsx";

export default function Notification({
  showNotification,
  toggleNotification,
}: {
  showNotification: boolean;
  toggleNotification: (value: boolean) => void;
}) {
  const { notification } = useSelector(
    (state: CombineReducersState) => state.notificationReducer
  );
  const intl = useIntl();
  const [readButton, setReadButton] = useState<boolean>(false);
  const [statusRead, setStatusRead] = useState<boolean>(false);
  const { pathname } = useLocation();

  const handleClick = () => {
    setReadButton(!readButton);
  };

  const navigate = useNavigate();
  const handleClickNotification = async (companiId, notificationId) => {
    await apiCall(HTTPMethod.POST, `/notifications/${notificationId}/reading`, {
      reading: true,
    }).then(() => {
      getNotification();
      toggleNotification(false);
      const root = pathname.includes("dashboard") ? "/dashboard" : "";
      navigate(`${root}/companies/${companiId}`);
    });
  };
  const handleClickImportNotification = async (notificationId) => {
    await apiCall(HTTPMethod.POST, `/notifications/${notificationId}/reading`, {
      reading: true,
    }).then(() => {
      getNotification();
      toggleNotification(false);
      navigate(`/imports_companies?company.companyGroups.isProcessed=false`);
    });
  };

  const typeAllegati = [
    "add attachments",
    "add inventory",
    "add notice of sale",
    "add appraisal",
  ];
  const typeMessages = ["new note"];
  const typeImport = ["new import"];
  const typeImportProcedure = ["import procedure"];
  const typeInsertProcedure = ["insert procedure"];
  const typeCloseProcedure = ["close procedure"];
  const changeRelevanceProcedure = ["change relevance"];

  const checkTypeNotification = (type) => {
    switch (true) {
      case typeAllegati.includes(type):
        if (type === "add inventory") {
          return ETypeNotifications.INVENTORY;
        } else if (type === "add notice of sale") {
          return ETypeNotifications.NOTICE_OF_SALE;
        } else if (type === "add appraisal") {
          return ETypeNotifications.APPRAISAL;
        } else {
          return ETypeNotifications.ATTACHMENTS;
        }
      case typeMessages.includes(type):
        return ETypeNotifications.MESSAGES;
      case typeImport.includes(type):
        return ETypeNotifications.IMPORT;
      case typeImportProcedure.includes(type):
        return ETypeNotifications.IMPORT_PROCEDURE;
      case typeInsertProcedure.includes(type):
        return ETypeNotifications.INSERT_PROCEDURE;
      case typeCloseProcedure.includes(type):
        return ETypeNotifications.CLOSE_PROCEDURE;
      case changeRelevanceProcedure.includes(type):
        return ETypeNotifications.CHANGE_RELEVANCE;
      default:
        return ETypeNotifications.OTHER;
    }
  };
  const buttonText = readButton ? "Lette" : "Non lette";
  const setStatus = readButton
    ? () => setStatusRead(true)
    : () => setStatusRead(false);
  const filterFields = useMemo(() => {
    return (
      notification && notification.filter((el) => el.isRead === statusRead)
    );
  }, [notification, statusRead]);

  return (
    <div
      id="kt_activities"
      className={clsx(
        showNotification
          ? "bg-body drawer drawer-end drawer-on"
          : "bg-body drawer drawer-end", "drawer-notification"
      )}
      data-kt-drawer="true"
      data-kt-drawer-name="activities"
      data-kt-drawer-activate="true"
      data-kt-drawer-overlay="false"
      data-kt-drawer-width="{default:'300px', 'lg': '900px'}"
      data-kt-drawer-direction="end"
      data-kt-drawer-toggle="#kt_activities_toggle"
      data-kt-drawer-close="#kt_activities_close"
      style={{ width: "900px", border: "1px solid grey" }}
    >
      <div className="card w-100 shadow-none border-0 rounded-0">
        {/*--begin::Header*/}
        <div className="card-header  d-flex" id="kt_activities_header">
          <h3 className="card-title fw-bold text-dark">NOTIFICHE</h3>
          <div className="d-flex align-items-center gap-4">
            <p className="fw-bold fs-4 text-center mb-0">mostra: </p>
            <div className="dropdown">
              <button
                onClick={setStatus}
                className="btn dropdown-toggle border border-black rounded-pill"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{
                  position: "relative",
                  width: readButton ? "89px" : "auto",
                }}
              >
                {buttonText}
              </button>
              <ul className="dropdown-menu border border-black rounded">
                <li onClick={() => setStatusRead(!readButton)}>
                  <div className="dropdown-item" onClick={handleClick}>
                    {readButton ? "Non lette" : "Lette"}
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="card-toolbar">
            <button
              onClick={() => toggleNotification(false)}
              type="button"
              className="btn btn-sm btn-icon btn-active-light-primary me-n5"
              id="kt_activities_close"
            >
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
        </div>
        {/*--end::Header*/}

        {/*--begin::Body*/}
        <div className="card-body position-relative" id="kt_activities_body">
          {/*--begin::Content*/}
          <div
            id="kt_activities_scroll"
            className="position-relative scroll-y me-n5 pe-5"
            data-kt-scroll="true"
            data-kt-scroll-height="auto"
            data-kt-scroll-wrappers="#kt_activities_body"
            data-kt-scroll-dependencies="#kt_activities_header, #kt_activities_footer"
            data-kt-scroll-offset="5px"
            style={{ height: "732px" }}
          >
            {/*--begin::Timeline items*/}
            <div className="timeline timeline-border-dashed">
              {filterFields && filterFields.length > 0 ? (
                filterFields.map((element, index) => (
                  <React.Fragment key={index}>
                    <Messages
                      typeNotifications={checkTypeNotification(element.type)}
                      onClick={(e) => {
                        e.stopPropagation();
                        if(element?.company){
                          handleClickNotification(
                              element?.company?.["@id"].split("/").pop(),
                              element?.["@id"]?.split("/").pop()
                          );
                        }
                        if(checkTypeNotification(element.type) == ETypeNotifications.IMPORT){
                          handleClickImportNotification(element?.["@id"]?.split("/").pop());
                        }
                      }}
                      notifications={element}
                    />
                  </React.Fragment>
                ))
              ) : (
                <div className="empty-element">
                  <i className="fa-regular fa-file"></i>
                  <p>{intl.formatMessage({ id: "Empty.notifications" })}</p>
                </div>
              )}
            </div>
            {/*--end::Timeline items*/}
          </div>
          {/*--end::Content*/}
        </div>
        {/*--end::Body*/}
        <div className="card-footer d-none"></div>
      </div>
    </div>
  );
}

// X ATTACHMENTS

// remove followers
// add followers
// change followers
// change relevance
// change absence
// change entrusted
// change sold
// new import
// change assignit
// new general
// change undefined
// new urgent
// insert procedure
// change directsell
