import clsx from "clsx";
import { useIntl } from "react-intl";

export default function Stepper({
  steps,
  currentStep,
  onChangeStep,
}: {
  steps: string[];
  currentStep: string;
  onChangeStep: (e: string) => void;
}) {
  const checIfActive = (step) => {
    const indexCurrentStep = steps.findIndex((el) => el === currentStep);
    const indexStep = steps.findIndex((el) => el === step);

    if (indexCurrentStep !== -1 && indexStep !== -1) {
      return indexCurrentStep >= indexStep;
    }
    return false;
  };
  const calculateProgress = () => {
    const indexCurrentStep = steps.findIndex((el) => el === currentStep);
     const valueStep = 100 / (steps.length -1) 

    return  indexCurrentStep * valueStep;
  };
  const intl = useIntl();
  return (
    <div
      className="position-relative d-flex justify-content-between  align-items-center"
      style={{ height: "120px" }}
    >
      <div
        className="position-absolute"
        style={{ height: "1px", background: "#D9D9D9", width: "100%", left: 0 }}
      />
      <div
        className="position-absolute"
        style={{
          height: "1px",
          background: "#3699FF",
          width: `${calculateProgress()}%`,
          left: 0,
        }}
      />
      {steps.map((step) => {
        return (
          <div
            key={step}
            className={clsx(
              "position-relative d-flex flex-column align-items-center"
            )}
            onClick={() => onChangeStep(step)}
          >
            <div
              className="rounded-circle"
              style={{
                height: "14px",
                width: "14px",
                backgroundColor: checIfActive(step) ? "#3699FF" : "#D9D9D9",
              }}
            ></div>
            <div
              className={clsx("position-absolute text-center top-100 fw-bold")}
              style={{ opacity: checIfActive(step) ? 1 : "0.5" }}
            >
              {intl.formatMessage({ id: `Wizard.steps.${step}` })}
            </div>
          </div>
        );
      })}
    </div>
  );
}
