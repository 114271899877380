import {parametersMapping} from "../../../../json/parametersMapping.ts";
import {FormattedMessage} from "react-intl";
import clsx from "clsx";
import {Icon} from "../../../../models/Icon/Icon.tsx";
import {EIconType} from "../../../../utils/helper.tsx";

const categoryIcons = {
    sales: "savings",
    employee: "groups",
    capital: "trending_up"
};

export function CardAltriParametri({ data }) {
    const categories = ["sales", "employee", "capital"]; // Ordine delle colonne

    // Mappa i dati selezionati nel globalState con le relative categorie
    const formattedData = categories.map((category) =>
        parametersMapping
            .filter((param) => param.category === category)
            .map((param) => {
                const selectedItem = data[category]?.find((item) => item.code === param.key);
                return {
                    key: param.key,
                    icon: param.icon,
                    value: selectedItem?.value === "MEDIUM" ? " " : selectedItem?.value || " ",
                    selected: !!selectedItem && selectedItem?.value !== "MEDIUM",
                };
            })
    );

    return (
        <div>
            <table className="table align-middle gs-0">
                <thead className="border-bottom border-secondary">
                <tr>
                    {categories.map((category) => (
                        <th key={category} className="fw-bold ">
                            <div className="d-flex gap-2 align-items-center">
                                <Icon
                                    typeIcon="material-icons-outlined"
                                    nameIcon={categoryIcons[category]}
                                    type={EIconType.MATERIAL}
                                />
                                <FormattedMessage id={`Wizard.altri-parametri.${category}`}/>
                                </div>
                        </th>
                        ))}
                </tr>
                </thead>
                <tbody>
                {[0, 1, 2, 3].map((row) => (
                    <tr key={row}>
                        {formattedData.map((categoryData, index) => {
                            const item = categoryData[row];
                            return (
                                <td
                                    key={index}
                                    className={clsx(
                                        "fw-bold text-black",
                                        item?.selected ? "" : "text-opacity-75"
                                    )}
                                    style={{ opacity: item?.selected ? "100%" : "30%" }}
                                >
                                    {item ? (
                                        <>
                                            <FormattedMessage id={`Wizard.altri-parametri.${item.key}`} />
                                            {" : "}
                                            <span className="text-success fw-bold">
                                <FormattedMessage id={`Wizard.relevance.${item.value}`} defaultMessage=" " />
                              </span>
                                        </>
                                    ) : ("-"
                                    )}
                                </td>
                            );
                        })}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
}
